import { html } from 'lit-element';
import { virtual } from 'haunted/web.js';
import { SVG_PLUS, SVG_MINUS } from '../svgs.js';

/**
 * This was initially a separate component but this caused issues since the ID attr on the web
 * component was updated but the event would have the ID of the previously rendered component.
 * This means on a PDP with multiple options, you would change the dropdown to another option
 * and when changing the qty it would change the qty of the previously rendered item.
 *
 * Very odd... but virtual components work well see here for more info
 * https://github.com/matthewp/haunted#virtual-components
 * SCSS also, needs to be imported into the component separately!
 */
export default virtual((quantity, packSize, id, onQtyUpdated) => {
  const updateQty = (el, changeQty) => {
    // Calculate wanted quantity, ensure min value is min pack size.
    let currentQty = el.target.parentNode.children[1].value;
    if (!currentQty) {
      currentQty = 0;
    }
    const qty = Math.max(parseInt(currentQty, 10) + parseInt(changeQty, 10), 0);
    const event = new CustomEvent('qty-updated', { detail: { qty, id } });
    onQtyUpdated(event);
  };
  return html`
    <div class="quantity-selector-wrapper">
      <button class="btn-quantity-selector" tabindex="-1" @click=${el => updateQty(el, -packSize)}>
        ${SVG_MINUS}
      </button>
      <input
        type="number"
        class="text-input input-quantity"
        .value=${quantity}
        step=${packSize}
        @change=${el => updateQty(el, 0)}
      />
      <button class="btn-quantity-selector" tabindex="-1" @click=${el => updateQty(el, packSize)}>
        ${SVG_PLUS}
      </button>
    </div>
  `;
});
