import { get } from '@appnest/lit-translate';
import { html } from 'lit-element';
import { useEffect, useState, useCallback } from 'haunted/web.js';
import { classMap } from 'lit-html/directives/class-map';
import styles from './SparkToast.scss';
import { addComponentToPage } from '../utils.js';
import { SVG_CLOSE } from '../svgs.js';

function SparkToast() {
  const [toasts, setToasts] = useState([]);
  let id = 0;

  // todo turn the below into a provider
  const addToast = useCallback(
    item => {
      id += 1;
      setToasts(toastsToUpdate => [...toastsToUpdate, { id, ...item }]);
      return id;
    },
    [setToasts]
  );

  const removeToast = useCallback(
    toastId =>
      setToasts(toastsToUpdate =>
        toastsToUpdate.length > 1 ? toastsToUpdate.filter(t => t.id !== toastId) : []
      ),
    [setToasts]
  );

  useEffect(() => {
    const listener = event => {
      id = addToast({
        type: event.detail.type,
        title: event.detail.title ?? '',
        showCartLink: event.detail.showCartLink ?? false,
      });
      setTimeout(() => removeToast(id), 7000);
    };
    window.addEventListener('spark-new-toast', listener);
    return () => {
      window.removeEventListener('spark-new-toast', listener);
    };
  });
  const onOpenCart = toastId => {
    addComponentToPage('spark-drawer');
    removeToast(toastId);
  };

  const toastHtml = t => {
    return html`
      <div class="toast--inner ${classMap({ [`toast--inner-${t.type}`]: true })}">
        <div class="toast--inner-svg">
          <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="icon-success"
          >
            <circle cx="25" cy="25" r="25" fill="#28AB76" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M32.5373 18L35 20.3333L22.6867 32L22.5366 31.8576L22.388 32L15 25L17.4627 22.6667L22.5366 27.4755L32.5373 18Z"
              fill="white"
            />
          </svg>

          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="icon-warning"
          >
            <path
              d="M16.0003 2.66663C8.66699 2.66663 2.66699 8.66663 2.66699 16C2.66699 23.3333 8.66699 29.3333 16.0003 29.3333C23.3337 29.3333 29.3337 23.3333 29.3337 16C29.3337 8.66663 23.3337 2.66663 16.0003 2.66663ZM16.0003 22.6666C15.2003 22.6666 14.667 22.1333 14.667 21.3333C14.667 20.5333 15.2003 20 16.0003 20C16.8003 20 17.3337 20.5333 17.3337 21.3333C17.3337 22.1333 16.8003 22.6666 16.0003 22.6666ZM17.3337 16C17.3337 16.8 16.8003 17.3333 16.0003 17.3333C15.2003 17.3333 14.667 16.8 14.667 16V10.6666C14.667 9.86663 15.2003 9.33329 16.0003 9.33329C16.8003 9.33329 17.3337 9.86663 17.3337 10.6666V16Z"
              fill="#EF0000"
            />
          </svg>
        </div>

        <div class="toast--inner-text">
          <h4>${t.title}</h4>
          ${t.showCartLink
            ? html`<button @click=${() => onOpenCart(t.id)} class="toast--inner-order btn-link">
                ${get('global.toast.view-cart')}
              </button>`
            : ''}
        </div>

        <button @click=${() => removeToast(t.id)} class="toast--inner-close">${SVG_CLOSE}</button>
      </div>
    `;
  };

  return html`
    <style>
      ${styles}
    </style>
    <div class="toast">${toasts.map(t => toastHtml(t))}</div>
  `;
}

export default SparkToast;
