import { html } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';
import styles from './SparkLoading.scss';

function SparkLoading({ size }) {
  return html`
    <style>
      ${styles}
    </style>
    <div
      class="preloader-wrapper active ${classMap({
        small: size === 'small',
        big: size !== 'small',
      })}"
    >
      <div class="spinner-layer spinner-blue-only">
        <div class="circle-clipper left">
          <div class="circle"></div>
        </div>
        <div class="gap-patch">
          <div class="circle"></div>
        </div>
        <div class="circle-clipper right">
          <div class="circle"></div>
        </div>
      </div>
    </div>
  `;
}

export default SparkLoading;
