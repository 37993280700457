import { html } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';
import { SVG_WARN } from '../svgs.js';
import styles from './SparkMessage.scss';

function SparkMessage({ title, message, type }) {
  return html`
    <style>
      ${styles}
    </style>
    <div class="message ${classMap({ [`message-${type}`]: true })}">
      ${type === 'warn' ? SVG_WARN : ''} ${title ? html`<h4>${title}</h4>` : ''}
      ${message !== true ? html`<p>${message}</p>` : ''}
    </div>
  `;
}
export default SparkMessage;
