import { html } from 'lit-element';
import { useState, useEffect } from 'haunted/web.js';
import { get } from '@appnest/lit-translate';
import { classMap } from 'lit-html/directives/class-map';
import styles from './SparkPDP.scss';
import spark, { LS_USER_DATA } from '../spark.js';
import SparkQtySelect from './SparkQtySelect.js';
import {
  calculateCartPriceForProduct,
  calculateFromPrice,
  createCurrencyFormatter,
  createNumberFormatter,
  getPackSize,
  useLocalStorage,
} from '../utils.js';
import { SVG_CIRCLE_ERROR, SVG_CIRCLE_HISTORY, SVG_CIRCLE_SUCCESS } from '../svgs.js';
import { EVENT_UPDATE_PRICE_DATA } from '../priceData.js';

function calcExtendedStockStatus(variant) {
  switch (true) {
    case variant.stockStatus === 'backorder':
      return 'back-order';
    case variant.stockQty <= 0:
      return 'out-of-stock';
    case variant.stockQty <= window.spark.options.display.stock.last:
      return 'last-stock';
    case variant.stockQty <= window.spark.options.display.stock.low:
      return 'low-stock';
    default:
  }
  return 'in-stock';
}

function SparkPDP({ parentId }) {
  const [user] = useLocalStorage(LS_USER_DATA, null);
  const [productState, setProductState] = useState(null);
  const [addToCartLoading, setAddToCartLoading] = useState(false);
  const [qtyTracking, setQtyTracking] = useState([]);
  const [productOptions, setProductOptions] = useState([]);

  const setQtyTrackingFromProducts = variants => {
    setQtyTracking(
      variants.map(variant => {
        const packSize = getPackSize(variant);
        return {
          id: variant.id,
          sku: variant.sku,
          packSize,
          qty: variants.length === 1 ? packSize : 0,
        };
      })
    );
  };

  const setData = state => {
    setProductState(state);
    if (!state.data) {
      setProductOptions([]);
      return;
    }
    const productData = state.data;
    /**
     * Setup product options dropdowns
     */
    const options = [];
    // Sort the variants by position
    productData.variants.sort((a, b) => a.position - b.position);
    let hasDefault = false;
    productData.variants.forEach(variant => {
      variant.options.forEach((option, index) => {
        let foundOptionGroup = options.find(o => o.name === option.group);
        if (!foundOptionGroup) {
          foundOptionGroup = {
            name: option.group,
            values: [],
            dropdown: index !== variant.options.length - 1, // If its the last option, its not a dropdown!
          };
          options.push(foundOptionGroup);
        }
        let foundOptionGroupValue = foundOptionGroup.values.find(o => o.name === option.value);
        if (!foundOptionGroupValue) {
          foundOptionGroupValue = {
            name: option.value,
            selected: false,
          };
          foundOptionGroup.values.push(foundOptionGroupValue);
        }
        if (foundOptionGroup.dropdown && variant.position === 1) {
          foundOptionGroupValue.selected = true;
          hasDefault = true;
        }
      });
    });
    if (!hasDefault) {
      // If no default is setup, choose the first of each option to be selected
      options.forEach(option => {
        if (option.dropdown) {
          // todo remove the below!
          // eslint-disable-next-line no-param-reassign
          option.values[0].selected = true;
        }
      });
    }
    setProductOptions(options);
    setQtyTrackingFromProducts(productData.variants);
  };

  // Trigger a fetch on user or parentId updates
  useEffect(() => {
    if (!user || !parentId) {
      // todo handle user logout here, i.e. user is now null
      return;
    }
    setData(window.spark.priceData.fetch(parentId));
  }, [user, parentId]);

  // Handle messaging for updates via the message bus
  useEffect(() => {
    const handleDataUpdate = event => {
      if (event.detail.parentId === parentId) {
        setData(event.detail.state);
      }
    };
    window.spark.msgBus.addEventListener(EVENT_UPDATE_PRICE_DATA, handleDataUpdate);
    return () => {
      window.spark.msgBus.removeEventListener(EVENT_UPDATE_PRICE_DATA, handleDataUpdate);
    };
  }, []);

  const onAddToBasketAction = async () => {
    const items = qtyTracking.filter(q => {
      return q.qty > 0;
    });
    if (items.length === 0) {
      return;
    }
    setAddToCartLoading(true);
    await spark.updateCart(
      {
        products: items.map(n => {
          return { sku: n.sku, absoluteQuantity: n.qty };
        }),
      },
      false
    );
    // Reset product quantity
    // todo remove the qty added to cart and leave any which didn't instead of resetting to default
    setQtyTrackingFromProducts(productState.data.variants);
    setAddToCartLoading(false);
  };
  const onQtyUpdated = e => {
    const updatedQty = qtyTracking;
    const qtyRecord = updatedQty.find(x => x.id === e.detail.id);
    qtyRecord.qty = e.detail.qty;
    setQtyTracking(updatedQty);
  };
  const onProductOptionChange = e => {
    const update = productOptions;
    const optionGroup = update.find(group => group.name === e.target.name);
    optionGroup.values = optionGroup.values.map(value => {
      const updatedValue = value;
      updatedValue.selected = false;
      return updatedValue;
    });
    const optionValue = optionGroup.values.find(value => e.target.value === value.name);
    optionValue.selected = true;
    setProductOptions(update);
  };

  if (!user) {
    return html`<!-- Spark User Logged Out -->`;
  }
  if (!productState || productState.loading) {
    return html`
      <style>
        ${styles}
      </style>

      <div class="loading-prices"></div>
    `;
  }
  if (productState.notFound) {
    return html`<!-- Spark Product not found -->`;
  }
  if (productState.error) {
    return html`
      <style>
        ${styles}
      </style>
      Error
    `;
  }

  let filteredVariants = [];
  if (productOptions.length > 1) {
    productState.data.variants.forEach(variant => {
      let found = true;
      productOptions.forEach(optionGroup =>
        optionGroup.values.forEach(optionValue => {
          if (optionGroup.dropdown && optionValue.selected) {
            if (
              !variant.options.some(
                option => option.group === optionGroup.name && option.value === optionValue.name
              )
            ) {
              found = false;
            }
          }
        })
      );
      if (found) {
        filteredVariants.push(variant);
      }
    });
  } else {
    filteredVariants = productState.data.variants;
  }

  // Ensure From Price is the lowest across variants and price breaks
  const [noOfPrices, fromPrice, currencyCode] = calculateFromPrice(productState.data);
  const moneyFormat = createCurrencyFormatter(currencyCode);

  const rrpPrice = productState.data.variants.reduce((lowestPrice, variant) => {
    return variant.rrp !== null && variant.rrp < (lowestPrice ?? Infinity)
      ? variant.rrp
      : lowestPrice;
  }, null);

  let pdpTotalPrice = 0;
  qtyTracking.forEach(qtyRecord => {
    pdpTotalPrice +=
      calculateCartPriceForProduct(
        productState.data.variants.find(x => x.id === qtyRecord.id),
        qtyRecord.qty
      )?.total ?? 0;
  });
  const showPackSize = filteredVariants.some(variant =>
    variant.settings.some(x => x.key === 'packSize' && x.value !== null && x.value > 1)
  );

  const returnHtml = [];
  returnHtml.push(html`
    <div class="product-pricing">
      <div class="price-summary">
        ${fromPrice
          ? html`<h4>
              ${get('pdp.price.from-prefix')}
              ${noOfPrices > 1 ? get('pdp.price.from') : ''}${moneyFormat.format(fromPrice)}
            </h4>`
          : ''}
        ${rrpPrice ? html`<p>${get('pdp.price.rrp')}${moneyFormat.format(rrpPrice)}</p>` : ''}
      </div>
    </div>
  `);

  const showStockLevel = variant => {
    const numberFormatter = createNumberFormatter();
    const stockQty = Math.max(variant.stockQty, 0);
    if (stockQty > window.spark.options.display.stock.max) {
      return `${numberFormatter.format(window.spark.options.display.stock.max)}+`;
    }
    return numberFormatter.format(stockQty);
  };

  if (productState.data.variants.length > 1) {
    // Multiple Product Variants

    let dropdowns = '';
    if (productOptions.filter(x => x.dropdown).length) {
      /* eslint-disable lit-a11y/no-invalid-change-handler */
      dropdowns = html`
        <div class="input-field product-variant-select">
          ${productOptions
            .filter(x => x.dropdown)
            .map(
              optionGroup => html`
                <p>${optionGroup.name}</p>
                <select name="${optionGroup.name}" @change=${onProductOptionChange}>
                  ${optionGroup.values.map(option => {
                    return html`<option .selected=${option.selected}>${option.name}</option>`;
                  })}
                </select>
              `
            )}
        </div>
      `;
      /* eslint-enable lit-a11y/no-invalid-change-handler */
    }

    const hasQty = qtyTracking.some(item => item.qty > 0);
    const showStockQty = window.spark.options.display.stock.show;
    const hasImages = filteredVariants.some(item => item.cartImageUrl);

    const productRows = filteredVariants.map(variant => {
      const optionGroup = productOptions.find(x => !x.dropdown);
      const name = variant.options.find(x => x.group === optionGroup.name).value ?? '';
      const variantPackSize = getPackSize(variant);
      const qtyRecord = qtyTracking.find(x => x.id === variant.id);
      const price =
        variant.priceBreaks.find(x => x.quantity < qtyRecord.qty)?.price?.net ??
        variant.price?.net ??
        0;
      const oos = variant.stockStatus === 'out_of_stock';
      const stockStatus = calcExtendedStockStatus(variant);
      return html`
        <tr>
          ${hasImages
            ? html`<td class="variant--image hide-mobile">
                ${variant.cartImageUrl
                  ? html`
                      <img
                        src=${variant.cartImageUrl}
                        class="variant--image--large"
                        alt=${variant.name}
                      />
                      <img
                        src=${variant.cartImageUrl}
                        class="variant--image--small"
                        alt=${variant.name}
                      />
                    `
                  : ''}
              </td>`
            : ''}
          <td class="variant--name">
            <p>${name}</p>
            <span class="hide-sku">${variant.sku}</span>
            ${variant.stockStatus === 'backorder'
              ? html`<span class="multi-variant-stock-status back-order"
                  >${get('pdp.messaging.back-order')}</span
                >`
              : ''}
          </td>
          ${showPackSize ? html`<td class="hide-mobile">${variantPackSize}</td>` : ''}
          <td>
            <p>${price ? moneyFormat.format(price) : get('pdp.price.missing')}</p>
            ${variant.rrp ? html`<span>RRP:${moneyFormat.format(variant.rrp)}</span>` : ''}
          </td>
          ${showStockQty
            ? html`<td
                class="stock-status ${classMap({
                  'stock-status__low': stockStatus === 'low-stock',
                  'stock-status__last': stockStatus === 'last-stock',
                  'stock-status__none': stockStatus === 'out-of-stock',
                  'stock-status__backorder': stockStatus === 'back-order',
                })}"
              >
                <p>
                  <em></em>
                  <strong>${stockStatus === 'back-order' ? '' : showStockLevel(variant)}</strong>
                  <span class="stock-status">${get(`pdp.messaging.${stockStatus}`)}</span>
                </p>
              </td>`
            : ''}
          <td class="quantity-select">
            ${variant.price && !oos
              ? SparkQtySelect(qtyRecord.qty, qtyRecord.packSize, qtyRecord.id, onQtyUpdated)
              : html`
                  <p class="${oos ? 'out-of-stock' : 'un-sellable'}">
                    ${SVG_CIRCLE_ERROR}
                    <span> ${get(`pdp.messaging.${oos ? 'out-of-stock' : 'un-sellable'}`)} </span>
                  </p>
                `}
          </td>
        </tr>
      `;
    });

    returnHtml.push(html`
      <div class="price-variants">
        ${dropdowns}
        <table class="striped highlight product-variant-table">
          <thead>
            <tr>
              ${hasImages ? html`<th width="1" class="variant--image hide-mobile"></th>` : ''}
              <th>${productOptions.find(x => !x.dropdown).name}</th>
              ${showPackSize ? html`<th class="hide-mobile">${get('pdp.table.pack')}</th>` : ''}
              <th>${get('pdp.table.price')}</th>
              ${showStockQty ? html`<th>${get('pdp.table.stock')}</th>` : ''}
              <th>${get('pdp.table.qty')}</th>
            </tr>
          </thead>
          <tbody>
            ${productRows}
          </tbody>
        </table>
        ${showStockQty
          ? html`
              <ul class="stock-key">
                <li>${get('pdp.messaging.in-stock')}</li>
                <li class="out-of-stock">${get('pdp.messaging.out-of-stock')}</li>
                <li class="back-order">${get('pdp.messaging.back-order')}</li>
                <li class="low-stock">${get('pdp.messaging.low-stock')}</li>
                <li class="last-stock">${get('pdp.messaging.last-stock')}</li>
              </ul>
            `
          : ''}
      </div>

      <div class="button-purchase button-inline">
        <button
          class="btn-small no-margin ${classMap({
            'button-purchase--btn-loading': addToCartLoading,
          })}"
          @click=${onAddToBasketAction}
          ?disabled=${!hasQty || addToCartLoading}
        >
          <span class="purchase-amount"
            >${get('pdp.add-to-order.text')} (${moneyFormat.format(pdpTotalPrice)})</span
          >
          ${addToCartLoading
            ? html`<span class="loading-text">${get('pdp.add-to-order.loading')}</span>`
            : ``}
        </button>
        ${hasQty
          ? ''
          : html`<span class="product-disabled-message">${get('pdp.add-to-order.disabled')}</span>`}
      </div>
    `);
  } else {
    // Single Product Variant
    const variant = productState.data.variants[0];

    returnHtml.push(
      html`<p class="product-code hide-sku">${get('pdp.product-code')} ${variant.sku}</p> `
    );

    if (variant.price && variant.stockStatus === 'in_stock') {
      if (window.spark.options.display.stock.show) {
        const stockStatus = calcExtendedStockStatus(variant);
        returnHtml.push(
          html`
            <p
              class="stock-status ${classMap({
                'low-stock': stockStatus === 'low-stock',
                'last-stock': stockStatus === 'last-stock',
                'in-stock': stockStatus === 'in-stock',
              })}"
            >
              ${SVG_CIRCLE_SUCCESS}
              <span>${get('pdp.messaging.in-stock')}</span>
              <span class="stock-level"
                >${get(`pdp.messaging.stock-qty.${stockStatus}`, {
                  qty: showStockLevel(variant),
                })}</span
              >
            </p>
          `
        );
      } else {
        returnHtml.push(
          html`
            <p class="stock-status in-stock">
              ${SVG_CIRCLE_SUCCESS}
              <span>${get('pdp.messaging.in-stock')}</span>
            </p>
          `
        );
      }
    } else if (variant.price && variant.stockStatus === 'backorder') {
      returnHtml.push(
        html`
          <p class="stock-status back-order back-order-display">
            ${SVG_CIRCLE_HISTORY}
            <span>${get('pdp.messaging.back-order')}</span>
          </p>
        `
      );
    } else if (variant.stockStatus === 'out_of_stock') {
      returnHtml.push(
        html`
          <p class="stock-status out-of-stock">
            ${SVG_CIRCLE_ERROR}

            <span>${get('pdp.messaging.out-of-stock')}</span>
          </p>
        `
      );
    }

    if (variant.priceBreaks.length) {
      const priceBreakRows = [
        {
          qty: '1+',
          priceFriendly: moneyFormat.format(variant.price.net),
          savings: '-',
        },
      ];
      variant.priceBreaks.forEach(data => {
        priceBreakRows.push({
          qty: `${data.quantity}+`,
          priceFriendly: moneyFormat.format(data.price.net),
          savings: `${parseInt((data.price.net / variant.price.net - 1) * -100, 10)}%`,
        });
      });

      returnHtml.push(html`
        <table class="striped highlight reduced-height">
          <thead>
            <tr>
              <th>${get('pdp.price-breaks.qty')}</th>
              <th>${get('pdp.price-breaks.price')}</th>
              <th>${get('pdp.price-breaks.savings')}</th>
            </tr>
          </thead>

          <tbody>
            ${priceBreakRows.map(
              data => html`
                <tr>
                  <td>${data.qty}</td>
                  <td>${data.priceFriendly}</td>
                  <td class="savings">${data.savings}</td>
                </tr>
              `
            )}
          </tbody>
        </table>
      `);
    }

    if (showPackSize) {
      const packSize = getPackSize(variant);
      // todo this isn't pretty in anyway, figure out how to use interpolate with html
      returnHtml.push(html`
        <p class="pack-size">
          ${get('pdp.pack-size.start')}<strong>${packSize}</strong>${get('pdp.pack-size.end')}
        </p>
      `);
    }

    if (variant.price && variant.stockStatus !== 'out_of_stock') {
      // Ensure we have a price
      returnHtml.push(html`
        <div class="button-purchase">
          <div class="purchase-quantity">
            ${SparkQtySelect(
              qtyTracking[0].qty,
              qtyTracking[0].packSize,
              qtyTracking[0].id,
              onQtyUpdated
            )}
          </div>
          <button
            class="btn-small ${classMap({ 'button-purchase--btn-loading': addToCartLoading })}"
            @click=${onAddToBasketAction}
            ?disabled=${addToCartLoading}
          >
            <span class="purchase-amount"
              >${get('pdp.add-to-order.text')} (${moneyFormat.format(pdpTotalPrice)})</span
            >
            ${addToCartLoading
              ? html`<span class="loading-text">${get('pdp.add-to-order.loading')}</span>`
              : ``}
          </button>
        </div>
      `);
    }
  }
  return html`
    <style>
      ${styles}
    </style>

    <div class="product-pricing">${returnHtml}</div>
  `;
}

export default SparkPDP;
