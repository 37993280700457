import { html } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';
import { useState, useEffect } from 'haunted/web.js';
import { get } from '@appnest/lit-translate';
import styles from './SparkLogin.scss';
import spark from '../spark.js';
import { removeComponentToPage } from '../utils.js';
import { SVG_CLOSE } from '../svgs.js';

function SparkLogin() {
  const [formState, setFormState] = useState({ email: '', password: '' });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const [newTimer, setNewTimer] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setNewTimer(false);
    }, 1);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  const closeDialog = () => {
    setNewTimer(true);
    // This timeout here must equal the transition time within CSS
    setTimeout(() => removeComponentToPage('spark-login'), 500);
  };

  const setInputFormState = (key, value) => {
    setFormState({ ...formState, ...{ [key]: value } });
  };

  const onInputChange = e => {
    setInputFormState(e.target.name, e.target.value);
  };

  const onLogin = async e => {
    e.preventDefault();
    setError('');
    setLoading(true);
    try {
      await spark.login(formState.email, formState.password);
      closeDialog();
    } catch (loginError) {
      setError(get(`login.errors.${loginError.code === 401 ? 'invalid' : 'system'}`));
    }
    setLoading(false);
  };

  const onClose = e => {
    e.preventDefault();
    closeDialog();
  };

  /* eslint-disable lit-a11y/click-events-have-key-events */
  return html`
    <style>
      ${styles}
    </style>

    <div
      @click=${onClose}
      class="overlay ${classMap({ 'overlay--hidden': newTimer })}"
    ></div>

    <div class="login-modal-wrapper ${classMap({
      'login-modal-wrapper--hidden': newTimer,
    })}">
      <div class="inner container with-shadow">
        ${loading ? html`<spark-loading></spark-loading>` : ''}
        <div class="container-content ${classMap({ loading })}">
          <div class="header-title">
            <h1>${get('login.title')}</h1>
            <a href="#" @click=${onClose}>
              ${SVG_CLOSE}
            </a>

            <p>${get('login.text')}</p>
          </div>

          ${error ? html`<spark-message type="error" title=${error}></spark-message>` : ''}

          <form>
            <div class="input-field">
              <input
                name="email"
                type="email"
                class="validate"
                placeholder="${get('login.form.email.placeholder')}"
                required
                @change=${onInputChange}
              />
            </div>
            <div class="input-field">
              <input
                name="password"
                type="password"
                class="validate"
                placeholder="${get('login.form.password.placeholder')}"
                required
                @change=${onInputChange}
              />
            </div>
            <button type="submit" class="btn-small" @click=${onLogin}>
              ${get('login.form.sign-in.btn')}
            </button>

            <!--<p><a href="#">Forgot password?</a></p>-->
          </form>

          <!--<form id="forgot-password">
            <p>We'll send you details to update your password.</p>
            <div class="input-field">
              <input
                type="email"
                class="validate"
                placeholder="Enter your email address"
              />
            </div>
            <button type="submit" class="btn-small">Send password</button>
          </form>-->

          <spark-message type="warn" title=${get('login.register.title')} message=${get(
    'login.register.text'
  )}>
        </div>
      </div>
    </div>
  `;
}

export default SparkLogin;
