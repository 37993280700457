import { html } from 'lit-element';
import { useState, useEffect } from 'haunted/web.js';
import { get } from '@appnest/lit-translate';
import styles from './SparkProductPrice.scss';
import { LS_USER_DATA } from '../spark.js';
import { calculateFromPrice, createCurrencyFormatter, useLocalStorage } from '../utils.js';
import { EVENT_UPDATE_PRICE_DATA } from '../priceData.js';

function SparkProductPrice({ parentId }) {
  const [user] = useLocalStorage(LS_USER_DATA, null);
  const [productState, setProductState] = useState(null);

  // Trigger a fetch on user or parentId updates
  useEffect(() => {
    if (!user || !parentId) {
      return;
    }
    setProductState(window.spark.priceData.fetch(parentId));
  }, [user, parentId]);

  // Handle messaging for updates via the message bus
  useEffect(() => {
    const handleDataUpdate = event => {
      if (event.detail.parentId === parentId) {
        setProductState(event.detail.state);
      }
    };
    window.spark.msgBus.addEventListener(EVENT_UPDATE_PRICE_DATA, handleDataUpdate);
    return () => {
      window.spark.msgBus.removeEventListener(EVENT_UPDATE_PRICE_DATA, handleDataUpdate);
    };
  }, []);

  if (!user) {
    return html`<!-- Spark Price - User Logged Out -->`;
  }
  if (!productState || productState.loading) {
    return html`
      <style>
        ${styles}
      </style>

      <div class="loading">...</div>
    `;
  }
  if (productState.notFound) {
    return html`<span class="error-hidden">Spark Price - no product found</span>`;
  }
  if (productState.error) {
    return html`
      <style>
        ${styles}
      </style>
      Error
    `;
  }

  const [noOfPrices, fromPrice, currencyCode] = calculateFromPrice(productState.data);
  const moneyFormat = createCurrencyFormatter(currencyCode);

  return html`
    <style>
      ${styles}
    </style>
    <div class="product-price-wrapper">
      <p class="product-price">
        ${fromPrice
          ? html`${get('pdp.price.from-prefix')}${noOfPrices > 1
              ? get('pdp.price.from')
              : ''}${moneyFormat.format(fromPrice)}`
          : html`<span class="error-hidden">Spark Price - no pricing found</span>`}
      </p>
    </div>
  `;
}

export default SparkProductPrice;
