import { get } from '@appnest/lit-translate';
import spark, { LS_SAVED_TEMPLATES } from '../spark.js';
import {
  cartTemplateCreateMutation,
  cartTemplateDeleteMutation,
  cartTemplateLoadQuery,
} from '../graphql.js';
import { localStorageExternalUpdate, newToast } from '../utils.js';

export const loadCartTemplates = async () => {
  try {
    const response = await spark.fetch(['query {', cartTemplateLoadQuery(), '}'].join(''), {});
    if (response.status !== 200) {
      throw new Error('failed');
    }
    const result = await response.json();
    localStorageExternalUpdate(LS_SAVED_TEMPLATES, result.data.cartTemplateList);
  } catch (e) {
    newToast('error', get('global.toast.system-error'), false);
  }
};
export const saveCartTemplate = async (savedTemplateName, setSavedTemplates) => {
  try {
    const result = await spark.fetch(cartTemplateCreateMutation(), { name: savedTemplateName });
    if (result.status !== 200) {
      throw new Error('failed');
    }
    newToast('success', get('global.toast.saved-templates.save'), false);
    await loadCartTemplates(setSavedTemplates);
  } catch (e) {
    newToast('error', get('global.toast.system-error'), false);
  }
};
export const saveTemplateLoad = async (recreateCartFromTemplateId, setImportResults) => {
  try {
    let results = await spark.updateCart(
      { recreateCartFromTemplateId, clearCart: true },
      true,
      true
    );
    results = results.filter(x => !!x.messages.length);
    setImportResults(results);
  } catch (e) {
    newToast('error', get('global.toast.system-error'), false);
  }
};
export const saveTemplateDelete = async (id, setSavedTemplates) => {
  try {
    await spark.fetch(cartTemplateDeleteMutation(), { id });
  } catch (e) {
    newToast('error', get('global.toast.system-error'), false);
  }
  newToast('success', get('global.toast.saved-templates.delete'), false);
  await loadCartTemplates(setSavedTemplates);
};
