import { useState, useEffect } from 'haunted/web.js';

export function addComponentToPage(tagName, attrs) {
  if (!document.getElementsByName(tagName).length) {
    const el = document.createElement(tagName);
    for (const [k, v] of Object.entries(attrs ?? {})) {
      el.setAttribute(k, v);
    }
    document.body.appendChild(el);
  }
}
export function removeComponentToPage(tagName) {
  const elem = document.getElementsByTagName(tagName);
  if (elem.length) {
    elem[0].parentNode.removeChild(elem[0]);
  }
}

export function newToast(type, title, showCartLink) {
  window.dispatchEvent(
    new CustomEvent('spark-new-toast', {
      detail: {
        type,
        title,
        showCartLink: showCartLink ?? false,
      },
    })
  );
}

/**
 * Generates a product link from the slug and the product link configuration option.
 * @param productSlug
 * @returns {string}
 */
export function generateProductLink(productSlug) {
  return window.spark.options.productLink.replace(':product-slug:', productSlug);
}

const styleNode = document.createElement('style');
styleNode.id = 'injected-by-spark-layer';
document.head.appendChild(styleNode);

export function applyBaseCSS(isLoggedIn) {
  while (styleNode.sheet.cssRules.length) {
    styleNode.sheet.deleteRule(0);
  }
  if (isLoggedIn) {
    styleNode.sheet.insertRule('[data-spark="b2c-only"] { display:none !important; }');
    styleNode.sheet.insertRule('[data-spark="b2b-only"] { display:block }');
  } else {
    styleNode.sheet.insertRule('[data-spark="b2c-only"] { display:block }');
    styleNode.sheet.insertRule('[data-spark="b2b-only"] { display:none !important; }');
  }
}

export function getPackSize(variant) {
  return variant.settings.find(setting => setting.key === 'packSize')?.value ?? 1;
}

export function calculateCartPriceForProduct(variant, qty) {
  let priceBreak = null;
  if (variant?.priceBreaks.length) {
    const priceBreaks = variant.priceBreaks.sort((a, b) => a.quantity - b.quantity);
    priceBreak = priceBreaks.find(x => x.quantity >= qty);
  }
  const unit = priceBreak?.quantity <= qty ? priceBreak.price.net : variant?.price?.net ?? 0;
  const total = unit * qty;
  return {
    total,
    unit,
  };
}

/**
 * When update local storage outside of a component, we need to trigger an event to cause an update
 * when other components
 * @param key
 * @param valueToStore
 */
export function localStorageExternalUpdate(key, valueToStore) {
  window.localStorage.setItem(key, JSON.stringify(valueToStore));
  window.dispatchEvent(
    new CustomEvent('onLocalStorageChange', {
      detail: { key, value: JSON.stringify(valueToStore) },
    })
  );
}

/**
 * Create number formatter for currency
 * @param currencyCode
 * @returns {Intl.NumberFormat}
 */
export function createCurrencyFormatter(currencyCode) {
  return new Intl.NumberFormat([], {
    style: 'currency',
    currency: currencyCode,
  });
}
/**
 * Create formatter for dates
 * @returns {Intl.DateTimeFormat}
 */
export function createDateTimeFormatter() {
  return new Intl.DateTimeFormat([]);
}
/**
 * Create formatter for numbers
 * @returns {Intl.NumberFormat}
 */
export function createNumberFormatter() {
  return new Intl.NumberFormat([]);
}
/**
 * Create formatter for dates
 * @returns {Intl.DateTimeFormat}
 */
export function shortDateFormat(date) {
  return new Intl.DateTimeFormat([]).format(new Date(date));
}

/**
 * @see https://usehooks.com/useLocalStorage/
 * @param key
 * @param initialValue
 * @returns {((function(): (any|undefined))|setValue)[]}
 */
export function useLocalStorage(key, initialValue) {
  // State to store our value - Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    // Get from local storage by key
    const item = window.localStorage.getItem(key);
    // Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : initialValue;
  });

  useEffect(() => {
    // The custom storage event allows us to update our component when a change occurs in localStorage in another component
    //  as the storage event only works in the context of other documents (eg. other browser tabs)
    const listener = event => {
      if (event.type === 'onLocalStorageChange' && event.detail.key === key) {
        setStoredValue(JSON.parse(event.detail.value));
      } else if (event.key === key) {
        setStoredValue(JSON.parse(event.newValue));
      }
    };
    window.addEventListener('onLocalStorageChange', listener);
    window.addEventListener('storage', listener);

    // Write default value to the local storage if there currently isn't any value there.
    // Don't however write a defaultValue that is null otherwise it'll trigger infinite updates.
    if (window.localStorage.getItem(key) === null && initialValue !== null) {
      window.localStorage.setItem(key, JSON.stringify(initialValue));
    }

    return () => {
      window.removeEventListener('onLocalStorageChange', listener);
      window.removeEventListener('storage', listener);
    };
  }, [key]);

  // Return a wrapped version of useState's setter function that persists the new value to localStorage.
  const setValue = value => {
    // Allow value to be a function so we have same API as useState
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    // Save state
    setStoredValue(valueToStore);
    // Save to local storage
    localStorageExternalUpdate(key, valueToStore);
  };

  return [storedValue, setValue];
}

/**
 * From Price is the lowest across variants and price breaks
 * @param productData
 * @return {(number|float|string)[]}
 */
export function calculateFromPrice(productData) {
  let noOfDifferentPrices = 0;
  let currencyCode = 'usd';
  const fromPrice = productData.variants.reduce((lowestPrice, variant) => {
    let lower = lowestPrice;
    if (variant.price !== null) {
      if (variant.price.net !== (lowestPrice ?? Infinity)) {
        noOfDifferentPrices += 1;
      }
      if (variant.price.net < (lowestPrice ?? Infinity)) {
        lower = variant.price.net;
        currencyCode = variant.price.currencyCode;
      }
    }
    return variant.priceBreaks.reduce((lowestPriceB, priceBreak) => {
      if (priceBreak.price.net < lowestPriceB) {
        noOfDifferentPrices += 1;
        currencyCode = priceBreak.price.currencyCode;
        return priceBreak.price.net;
      }
      return lowestPriceB;
    }, lower);
  }, null);
  return [noOfDifferentPrices, fromPrice, currencyCode];
}
/**
 * RRP Price is the lowest across variants and price breaks
 * @param productData
 * @return {float}
 */
export function calculateRRPPrice(productData) {
  return productData.variants.reduce((lowestPrice, variant) => {
    return variant.rrp !== null && variant.rrp < (lowestPrice ?? Infinity)
      ? variant.rrp
      : lowestPrice;
  }, null);
}
