import { html } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';
import { get } from '@appnest/lit-translate';
import { useRef, useState, useEffect } from 'haunted/web.js';
import { debounce } from 'throttle-debounce';
import papaparse from 'papaparse';
import styles from './SparkCart.scss';
import spark, {
  LS_CART_DATA,
  LS_CUSTOMER_ORDER_REF,
  LS_SAVED_TEMPLATES,
  LS_USER_DATA,
  LS_WIDE_MODE,
} from '../spark.js';
import { createCurrencyFormatter, getPackSize, newToast, useLocalStorage } from '../utils.js';
import { calculateCartMutation, completeCartMutation, quickOrderQuery } from '../graphql.js';
import { UI_STATE_ACCOUNT } from './SparkDrawer.js';
import {
  SVG_CLOSE,
  SVG_CLEAR,
  SVG_SAVE,
  SVG_IMPORT,
  SVG_EXPAND,
  SVG_ORDER_COMPLETE,
  SVG_QUICK_ORDER,
  SVG_ARROW_DOWN,
  SVG_PAYMENT_CREDIT,
  SVG_PAYMENT_QUOTE,
  SVG_PAYMENT_CARD,
  SVG_PAYMENT_HOKODO,
  SVG_PAYMENT_BNPL,
  SVG_CIRCLE_SUCCESS,
} from '../svgs.js';
import { saveCartTemplate, saveTemplateLoad } from '../common/savedTemplates.js';
import SparkPayHokodo from './payment-providers/SparkPayHokodo.js';
import SparkPayQuote from './payment-providers/SparkPayQuote.js';

const UI_STATE_CART = 'cart';
const UI_STATE_SHIPPING = 'shipping';
const UI_STATE_PAYMENT = 'payment';
const UI_STATE_THANKS = 'thanks';

const SEARCH_STATUS_AWAITING = 'awaiting';
const SEARCH_STATUS_SEARCHING = 'searching';
const SEARCH_STATUS_NOT_FOUND = 'not-found';
const SEARCH_STATUS_ADDED = 'product-added';

function SparkCart() {
  spark.fetchUserData();
  const [cart, setCart] = useLocalStorage(LS_CART_DATA, null);
  const [user] = useLocalStorage(LS_USER_DATA, null);
  const [wideMode, setWideMode] = useLocalStorage(LS_WIDE_MODE, false);
  const [optionsDropdownViewable, setOptionsDropdownViewable] = useState(false);
  const [qtyUpdates, setQtyUpdates] = useState({});
  const [updatingTotals, setUpdatingTotals] = useState(false);
  const [uiFlowStatus, setUiFlowStatus] = useState(UI_STATE_CART);
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [paymentType, setPaymentType] = useState('');
  const [shippingRateHandle, setShippingRateHandle] = useState('');
  const [calculateCartData, setCalculateCartData] = useState({});
  const [shownPaymentMethods, setShownPaymentMethods] = useState([]);
  const [customerOrderReference, setCustomerOrderReference] = useLocalStorage(
    LS_CUSTOMER_ORDER_REF,
    ''
  );
  const [extraPaymentData, setExtraPaymentData] = useState(false);

  const isEmpty = !cart?.items?.length;

  const onLogout = () => spark.logout();
  const onGoToAccount = () => {
    setUiFlowStatus(UI_STATE_CART);
    this.dispatchEvent(new CustomEvent('view-update', { detail: { view: UI_STATE_ACCOUNT } }));
  };
  const onGoToShippingStep = async () => {
    setUiFlowStatus(UI_STATE_SHIPPING);
    // Update cart to trigger fetching of addresses and stock!
    await spark.updateCart({}, true, false);
  };

  const handlePaymentTypeUpdate = selectedPaymentType => {
    // This ensures they complete the payment method setup.
    // If ExtraPaymentData is false, ensure the continue button is not available
    switch (selectedPaymentType) {
      case 'hokodoPayment':
        setExtraPaymentData(false);
        break;
      default:
        setExtraPaymentData({});
    }
    setPaymentType(selectedPaymentType);
  };
  const fetchCalculateCart = async (selectedRate = null) => {
    const handle = selectedRate ?? (!shippingRateHandle ? null : shippingRateHandle);
    setCheckoutLoading(true);
    const result = await spark.fetch(calculateCartMutation(), {
      platform: spark.options.platform,
      shippingRateHandle: handle,
    });
    if (result.status !== 200) {
      setCheckoutLoading(false);
      newToast('error', get(`global.toast.system-error`), false);
      onGoToShippingStep();
      return;
    }
    const res = await result.json();
    const data = res.data.calculateCart;
    setCalculateCartData(data);
    const shippingRateHandles = data.shippingMethods.map(x => x.handle);
    if (!shippingRateHandles.includes(handle)) {
      setShippingRateHandle(data.shippingMethods.length ? data.shippingMethods[0].handle : '');
    }
    // Ensure only allowed payment types are shown and in action during cart changes
    const allowedPaymentMethods = Object.keys(data.allowedPaymentMethods).filter(
      k => data.allowedPaymentMethods[k] === 'AVAILABLE'
    );
    if (allowedPaymentMethods.length && !allowedPaymentMethods.some(x => x === paymentType)) {
      handlePaymentTypeUpdate(allowedPaymentMethods[0]);
    }
    // Create list, they are ordered here to be ordered like this!
    setShownPaymentMethods(
      ['paymentOnAccount', 'paymentByInvoice', 'hokodoPayment', 'upfrontPayment', 'quote'].filter(
        x => data.allowedPaymentMethods[x] !== 'DISABLED'
      )
    );
    setCheckoutLoading(false);
  };

  const onGoToPaymentStep = () => {
    fetchCalculateCart();
    setUiFlowStatus(UI_STATE_PAYMENT);
  };
  const onGoToCart = () => setUiFlowStatus(UI_STATE_CART);

  const updateTotals = async updates2 => {
    setUpdatingTotals(true);
    const updates = [];
    Object.keys(updates2).forEach(itemKey =>
      updates.push({ itemKey, deltaQuantity: updates2[itemKey] })
    );
    await spark.updateCart({ products: updates }, true, false);
    setQtyUpdates({});
    setUpdatingTotals(false);
  };

  /**
   * For the persistent basket, if no basket is found locally, go fetch a basket!
   */
  const [loadingBasket, setLoadingBasket] = useState(false);
  useEffect(async () => {
    if (cart === null) {
      setLoadingBasket(true);
      await spark.updateCart({}, true, false);
      setLoadingBasket(false);
    }
  }, []);

  // todo this is way over complicated here, come back to this when you understand react hooks more
  const debouncedUpdateTotals = useRef(debounce(500, updates => updateTotals(updates))).current;

  const updateQty = (itemKey, qty) => {
    const updates = { ...qtyUpdates, [itemKey]: qty };
    setQtyUpdates(updates);
    debouncedUpdateTotals(updates);
  };

  const onClearCart = async () => {
    setOptionsDropdownViewable(false);
    setUpdatingTotals(true);
    await spark.updateCart({ clearCart: true }, true);
    setQtyUpdates({});
    setUpdatingTotals(false);
  };

  /**
   * Quick Search
   */
  const [searchSku, setSearchSku] = useState('');
  const [searchStatus, setSearchStatus] = useState(SEARCH_STATUS_AWAITING);

  const doSearch = async sku => {
    const result = await spark.fetch(quickOrderQuery(), { sku });
    if (result.status !== 200) {
      setSearchStatus(SEARCH_STATUS_NOT_FOUND);
      newToast('error', get(`global.toast.system-error`), false);
      return;
    }
    const res = await result.json();
    const { variant } = res.data;
    if (variant && variant.price) {
      await spark.updateCart(
        { products: [{ sku: variant.sku, absoluteQuantity: getPackSize(variant) }] },
        true
      );
      setSearchStatus(SEARCH_STATUS_ADDED);
      setTimeout(() => setSearchStatus(SEARCH_STATUS_AWAITING), 2500);
      setSearchSku('');
    } else {
      setSearchStatus(SEARCH_STATUS_NOT_FOUND);
      setTimeout(() => setSearchStatus(SEARCH_STATUS_AWAITING), 2500);
    }
  };
  const debouncedSearch = useRef(debounce(750, sku => doSearch(sku))).current;
  const onSkuChange = async e => {
    const sku = e.target.value;
    setSearchSku(sku);
    setSearchStatus(SEARCH_STATUS_SEARCHING);
    debouncedSearch(sku);
  };
  const quickSearchHTML = html`
    <div class="quickorder-panel ${classMap({ 'view-wide': wideMode })}">
      <span
        class=" ${classMap({
          'quickorder-panel__not-found': searchStatus === SEARCH_STATUS_NOT_FOUND,
          'quickorder-panel__product-added': searchStatus === SEARCH_STATUS_ADDED,
          hide: searchStatus !== SEARCH_STATUS_NOT_FOUND && searchStatus !== SEARCH_STATUS_ADDED,
        })}"
      >
        <span>${get(`cart.quick-order.${searchStatus}`)}</span>
      </span>
      <input
        type="text"
        @input=${onSkuChange}
        .value=${searchSku}
        class="validate"
        placeholder="${get('cart.quick-order.search-placeholder')}"
      />

      ${SVG_QUICK_ORDER}
    </div>
  `;
  /**
   * END Quick Search
   */
  /**
   * Import results
   */
  const [importResults, setImportResults] = useState([]);
  const importResultsHTML = importResults.length
    ? html`
        <div class="import-results">
          <button class="helper-close" @click=${() => setImportResults([])}>${SVG_CLOSE}</button>
          <h4>${get('cart.import.header', { num: importResults.length })}</h4>
          <p>${get('cart.import.p')}</p>
          <ul>
            ${importResults.map(
              x => html` <li>${x.sku} - ${get(`cart.import.messages.${x.messages[0].type}`)}</li> `
            )}
          </ul>
        </div>
      `
    : '';
  /**
   * CSV Import / Re-order
   */
  const [showCsvImport, setShowCsvImport] = useState(false);
  const onCsvImport = () => {
    setOptionsDropdownViewable(false);
    setShowCsvImport(!showCsvImport);
  };
  const runImport = async (products, clearCart) => {
    setLoadingBasket(true);
    let results = await spark.updateCart({ products, clearCart }, true, true);
    results = results.filter(x => !!x.messages.length);
    setImportResults(results);
    setLoadingBasket(false);
  };
  useEffect(() => {
    const listener = event => runImport(event.detail.products, event.detail.clearCart);
    window.addEventListener('msg-bus-reorder', listener);
    return () => window.removeEventListener('msg-bus-reorder', listener);
  }, []);
  const handleCsvImport = e => {
    setImportResults([]);
    const { files } = e.target;
    if (files[0]) {
      papaparse.parse(files[0], {
        skipEmptyLines: true,
        header: true,
        complete: parsedOutput => {
          const products = [];
          parsedOutput.data.forEach(item =>
            products.push({ sku: item.sku, absoluteQuantity: item.qty })
          );
          runImport(products);
        },
      });
      e.target.value = '';
      setShowCsvImport(false);
    }
  };
  /**
   * END CSV Import / Re-order
   */
  /**
   * Cart Templates
   */
  const [saveCartTemplateViewable, setSaveCartTemplateViewable] = useState(false);
  const [savedTemplateName, setSavedTemplateName] = useState('');
  const [savedTemplates, setSavedTemplates] = useLocalStorage(LS_SAVED_TEMPLATES, []);
  const onSaveTemplateShow = () => {
    if (isEmpty) {
      return;
    }
    setOptionsDropdownViewable(false);
    setSaveCartTemplateViewable(!saveCartTemplateViewable);
  };
  const onSavedTemplateNameChange = e => {
    setSavedTemplateName(e.target.value);
  };
  const onSaveCartTemplate = async () => {
    if (!savedTemplateName) {
      return;
    }
    setSaveCartTemplateViewable(false);
    setUpdatingTotals(true);
    await saveCartTemplate(savedTemplateName, setSavedTemplates);
    setUpdatingTotals(false);
  };
  const onSaveTemplateLoad = async recreateCartFromTemplateId => {
    setOptionsDropdownViewable(false);
    setUpdatingTotals(true);
    await saveTemplateLoad(recreateCartFromTemplateId, setImportResults);
    setQtyUpdates({});
    setUpdatingTotals(false);
  };
  // This effect is for load saved template from account section
  useEffect(() => {
    const listener = event => onSaveTemplateLoad(event.detail.id);
    window.addEventListener('msg-bus-cart-template-load', listener);
    return () => window.removeEventListener('msg-bus-cart-template-load', listener);
  }, []);
  /**
   * END Cart Templates
   */

  const moneyFormat = createCurrencyFormatter(cart?.total?.currencyCode ?? 'gbp');

  let checkoutHtml = '';
  const email = user?.userData?.email;
  const addressEditingAllowed = user?.userData?.addressEditingAllowed;

  const onCustomerReferenceUpdate = async e => {
    setCustomerOrderReference(e.target.value);
  };
  const handleDeliveryAddressUpdate = async id => {
    setCheckoutLoading(true);
    await spark.updateCart({ deliveryAddressId: id }, true);
    setCheckoutLoading(false);
  };
  const onCompleteOrder = async () => {
    setCheckoutLoading(true);
    if (cart.customerReference !== customerOrderReference) {
      const result = await spark.updateCart({ customerReference: customerOrderReference }, true);
      if (result === null) {
        // An error has occurred, stop processing, toast already set up the chain
        return;
      }
    }
    const response = await spark.fetch(completeCartMutation(), {
      paymentType,
      platform: spark.options.platform,
      extraPaymentData: JSON.stringify(extraPaymentData),
      shippingRateHandle,
    });
    if (response.status !== 200) {
      newToast('error', get(`global.toast.system-error`), false);
      return;
    }
    const result = await response.json();
    const goTo = result.data?.completeCart?.goTo || false;
    if (goTo) {
      // Shopify takes 2.5 seconds to load something, who knows -.-
      await new Promise(r => setTimeout(r, 3000));
      window.location = goTo;
      return;
    }
    await spark.externalClearBasket();
    setCheckoutLoading(false);
    setCart(null);
    setUiFlowStatus(UI_STATE_THANKS);
  };
  const handleShippingRateUpdate = selectedRate => {
    if (selectedRate !== shippingRateHandle) {
      setShippingRateHandle(selectedRate);
      fetchCalculateCart(selectedRate);
    }
  };

  if (uiFlowStatus === UI_STATE_SHIPPING) {
    const noShipAddressMessage = html`<div class="no-delivery">
      <spark-message
        type="warn"
        message=${get('cart.checkout.shipping-address.error')}
      ></spark-message>
    </div>`;
    const shippingAddressRadio = html`<div class="radio-wrap">
      ${cart.customerAddresses.map(
        address => html`
        <label
            class="${classMap({ selected: cart.deliveryAddressId === address.id })}"
            for="address-${address.id}"
          >
            <div class="radio-status">
              ${cart.deliveryAddressId === address.id ? SVG_ORDER_COMPLETE : ''}
            </div>
            <div class="radio-details">
              <input
                id="address-${address.id}"
                name="${address.id}"
                type="radio"
                .selected=${cart.deliveryAddressId === address.id}
                @click=${() => handleDeliveryAddressUpdate(address.id)}
              />
              <span title=${address.description}>${address.description}</span>
            </div>
          </label>
        </option>
      `
      )}
    </div>`;
    const onDeliveryAddressUpdate = e => handleDeliveryAddressUpdate(e.target.value);
    const shippingAddressSelect = html`<select
      @change=${onDeliveryAddressUpdate}
      @blur=${onDeliveryAddressUpdate}
    >
      ${cart.customerAddresses.map(
        address => html`
          <option value=${address.id} .selected=${address.id === cart.deliveryAddressId}>
            ${get('cart.checkout.shipping-address.send-to')}: ${address.description}
          </option>
        `
      )}
    </select>`;
    checkoutHtml = html`
      <div class="row complete-checkout ${classMap({ loading: checkoutLoading })}">
        <div class="input-field">
          <p>${get('cart.checkout.notes.label')}</p>
          <input
            ?required="${spark.options.display.customerReferenceRequired}"
            @change=${onCustomerReferenceUpdate}
            .value=${customerOrderReference}
            class="validate"
            maxlength="50"
            placeholder="${get('cart.checkout.notes.placeholder')}"
          />
          ${spark.options.display.customerReferenceRequired
            ? html` <span class="helper-text">${get('cart.checkout.notes.helper-text')}</span> `
            : ''}
        </div>
        <div class="input-field">
          <p>${get('cart.checkout.shipping-address.label')}</p>
          ${cart.customerAddresses.length < 4 ? shippingAddressRadio : shippingAddressSelect}
          ${addressEditingAllowed
            ? html`<button class="btn-link" @click=${onGoToAccount}>
                ${get(
                  `cart.checkout.shipping-address.${
                    addressEditingAllowed ? 'notes-edit-allowed' : 'notes'
                  }`
                )}
              </button>`
            : html`<span class="helper-text">${get(`cart.checkout.shipping-address.notes`)}</span>`}
          ${!cart.deliveryAddressId ? noShipAddressMessage : ''}
        </div>

        <div class="proceed-button-wrap">
          <button
            @click=${onGoToPaymentStep}
            .disabled=${!cart.deliveryAddressId ||
            (spark.options.display.customerReferenceRequired && customerOrderReference === '')}
            class="btn-large ${classMap({ loading: checkoutLoading })}"
          >
            <span> ${get('cart.checkout.payment-button')} </span>
          </button>
          <small>${get('cart.tax-shipping-message')}</small>
        </div>
      </div>
    `;
  } else if (uiFlowStatus === UI_STATE_PAYMENT) {
    let shippingMethods;
    if (!Object.keys(calculateCartData).length) {
      shippingMethods = html`<span class="review-step cart--loading"></span>`;
    } else if (!calculateCartData.shippingMethods.length) {
      shippingMethods = html`
        <spark-message
          type="warn"
          message=${get('cart.checkout.shipping-method.missing')}
        ></spark-message>
      `;
    } else if (calculateCartData?.shippingMethods?.length) {
      shippingMethods = html` <div class="radio-wrap">
        ${calculateCartData?.shippingMethods?.map(
          x => html`
            <label
              class="${classMap({ selected: shippingRateHandle === x.handle })}"
              for="shipping-${x.handle}"
            >
              <div class="radio-status">
                ${shippingRateHandle === x.handle ? SVG_ORDER_COMPLETE : ''}
              </div>
              <div class="radio-details">
                <input
                  id="shipping-${x.handle}"
                  name="${x.handle}"
                  type="radio"
                  .selected=${shippingRateHandle === x.handle}
                  @click=${() => handleShippingRateUpdate(x.handle)}
                />
                <span>${x.title}</span>
                <span>${moneyFormat.format(x.price)}</span>
              </div>
            </label>
          `
        ) ?? ''}
      </div>`;
    }
    const noPaymentMethodsHtml = html`
      <spark-message
        type="warn"
        message=${get('cart.checkout.payment-method.missing')}
      ></spark-message>
    `;
    const accountCreditNotAvailable = html`
      <spark-message
        type="warn"
        title=${get('cart.checkout.payment-method.credit-unavailable.title')}
        message=${get('cart.checkout.payment-method.credit-unavailable.message')}
      ></spark-message>
    `;
    const buildPaymentMethod = methodId => {
      if (paymentType === '' && calculateCartData.allowedPaymentMethods[methodId] !== 'AVAILABLE') {
        return '';
      }
      let svgIcon;
      switch (methodId) {
        case 'upfrontPayment':
          svgIcon = SVG_PAYMENT_CARD;
          break;
        case 'hokodoPayment':
          svgIcon = SVG_PAYMENT_BNPL;
          break;
        case 'quote':
          svgIcon = SVG_PAYMENT_QUOTE;
          break;
        default:
          svgIcon = SVG_PAYMENT_CREDIT;
      }
      return html`
        <label
          for="${methodId}"
          class="${classMap({
            selected: paymentType === methodId,
            disabled: calculateCartData?.allowedPaymentMethods?.[methodId] !== 'AVAILABLE',
          })}"
        >
          <div class="radio-status">${paymentType === methodId ? SVG_ORDER_COMPLETE : ''}</div>
          <div class="radio-details ${methodId}">
            <input
              id="${methodId}"
              name="${methodId}"
              type="radio"
              .selected=${paymentType === methodId}
              .disabled=${calculateCartData?.allowedPaymentMethods?.[methodId] !== 'AVAILABLE'}
              @click=${() => handlePaymentTypeUpdate(methodId)}
            />
            <span title="${get(`cart.checkout.payment-method.full.${methodId}`)}">
              ${get(`cart.checkout.payment-method.full.${methodId}`)}
              ${methodId === 'hokodoPayment'
                ? html`<span class="bnpl-hokodo">${SVG_PAYMENT_HOKODO}</span>`
                : ''}
            </span>
            <em>${svgIcon}</em>
          </div>
        </label>
      `;
    };
    let paymentMethods;
    if (!Object.keys(calculateCartData).length) {
      paymentMethods = html`<span class="review-step cart--loading"></span>`;
    } else if (paymentType === '') {
      paymentMethods = noPaymentMethodsHtml;
    } else {
      paymentMethods = html`<div class="radio-wrap">
        ${shownPaymentMethods.map(x => buildPaymentMethod(x))}
      </div>`;
    }
    let finalCheckoutBtn;
    switch (paymentType) {
      case 'quote':
        finalCheckoutBtn = 'cart.checkout.confirm-btn.quote';
        break;
      case 'upfrontPayment':
      case 'hokodoPayment':
        finalCheckoutBtn = 'cart.checkout.confirm-btn.checkout';
        break;
      default:
        finalCheckoutBtn = 'cart.checkout.confirm-btn.complete';
    }

    checkoutHtml = html`
      <div class="${classMap({ 'checkout-summary__loading': checkoutLoading })}">
        <div class="row complete-checkout">
          <div class="input-field">
            <p>${get('cart.checkout.shipping-method.label')}</p>
            ${shippingMethods}
          </div>

          <div class="input-field payment-show-desktop">
            <p>${get('cart.checkout.payment-method.label')}</p>
            ${calculateCartData?.allowedPaymentMethods?.paymentOnAccount ===
            'UNAVAILABLE_DUE_TO_CREDIT'
              ? accountCreditNotAvailable
              : ''}
            ${paymentMethods}
          </div>
        </div>

        <div class="row complete-checkout">
          ${paymentType === 'hokodoPayment' ? SparkPayHokodo(setExtraPaymentData) : ''}
          ${paymentType === 'quote' ? SparkPayQuote(setExtraPaymentData) : ''}
        </div>

        <div class="order-totals summary-screen">
          <div class="row sub-total">
            <div class="col l6">${get('global.totals.sub')}</div>
            <div class="col l6">${moneyFormat.format(calculateCartData?.subtotalCost ?? 0)}</div>
          </div>
          <div class="row shipping">
            <div class="col l6">${get('global.totals.shipping')}</div>
            <div class="col l6">${moneyFormat.format(calculateCartData?.shippingCost ?? 0)}</div>
          </div>
          <div class="row tax">
            <div class="col l6">${get('global.totals.tax')}</div>
            <div class="col l6">${moneyFormat.format(calculateCartData?.taxCost ?? 0)}</div>
          </div>

          <div class="divider"></div>

          <div class="row grand-total">
            <div class="col l6"><strong>${get('global.totals.total')}</strong></div>
            <div class="col l6">
              <strong>${moneyFormat.format(calculateCartData?.totalCost ?? 0)}</strong>
            </div>
          </div>
        </div>

        <p class="order-terms">
          <label>
            <input
              type="checkbox"
              class="filled-in"
              @click=${() => setTermsChecked(!termsChecked)}
              .checked=${termsChecked}
            />
            <span>
              ${get('cart.checkout.terms.start')}
              <a href=${spark.options.termsAndConditionsLink} target="_blank">
                ${get('cart.checkout.terms.link')}
              </a>
              ${get('cart.checkout.terms.end')}
            </span>
          </label>
        </p>

        <button
          type="submit"
          @click=${onCompleteOrder}
          class="btn-large"
          .disabled=${checkoutLoading ||
          !termsChecked ||
          !paymentType ||
          shippingRateHandle === '' ||
          extraPaymentData === false}
        >
          <span>
            ${get(finalCheckoutBtn, {
              total: moneyFormat.format(calculateCartData?.totalCost ?? 0),
            })}
          </span>
        </button>
      </div>
    `;
  } else if (uiFlowStatus === UI_STATE_THANKS) {
    const orderType = paymentType === 'quote' ? 'quote' : 'payment';
    checkoutHtml = html`
      <div class="order-complete">
        ${SVG_ORDER_COMPLETE}
        <h1>${get(`cart.thanks.${orderType}.main`)}</h1>
        <p>${get(`cart.thanks.${orderType}.blurb`, { email })}</p>
      </div>
    `;
  }

  return html`
    <style>
      ${styles}
    </style>

    <div class="header-title">
      <h1>${get(`cart.header.title`)}</h1>
      <p class="account-active">
        ${get('cart.header.who', { email })}
        <button @click=${onLogout}>${get('cart.header.logout')}</button>
      </p>

      <button @click=${() => setWideMode(!wideMode)} class="btn-view-wide">
        ${SVG_EXPAND}
        <span>${get(`drawer.button-view.${wideMode ? 'reduce' : 'expand'}`)}</span>
      </button>
    </div>

    <div class="header-options-wrapper">
      <div class="options-dropdown">
        <button
          type="button"
          @click=${() => setOptionsDropdownViewable(!optionsDropdownViewable)}
          class="options-button ${classMap({ active: optionsDropdownViewable })}"
        >
          ${get('cart.options-dropdown.label')} ${SVG_ARROW_DOWN}
        </button>

        <div class="options-items ${classMap({ hide: !optionsDropdownViewable })}">
          <button class="with-icon" @click=${onCsvImport}>
            ${SVG_IMPORT}
            <span>${get('cart.options-dropdown.csv-import')}</span>
          </button>
          <button
            @click=${onSaveTemplateShow}
            class="with-icon btn ${classMap({ disabled: isEmpty })}"
          >
            ${SVG_SAVE}
            <span>${get('cart.options-dropdown.save-cart')}</span>
          </button>
          <button class="with-icon" @click=${onClearCart}>
            ${SVG_CLEAR}
            <span>${get('cart.options-dropdown.clear-cart')}</span>
          </button>

          ${savedTemplates.length
            ? html`
                <span class="divider">Shopping Lists</span>
                ${savedTemplates
                  .slice(0, 5)
                  .map(
                    t => html`<button @click=${() => onSaveTemplateLoad(t.id)}>${t.name}</button>`
                  )}
                <button @click=${() => onGoToAccount()}>
                  ${get('cart.options-dropdown.view-all')}
                </button>
              `
            : ''}
        </div>
      </div>

      ${quickSearchHTML}
    </div>

    ${importResultsHTML}

    <div class="helper-template helper-block ${classMap({ hide: !saveCartTemplateViewable })}">
      <button class="helper-close" @click=${onSaveTemplateShow}>${SVG_CLOSE}</button>
      <h4>${get('cart.save-template.title')}</h4>
      <p>${get('cart.save-template.notes')}</p>
      <div class="input-field">
        <input
          class="validate"
          maxlength="50"
          @input=${onSavedTemplateNameChange}
          .value=${savedTemplateName}
          placeholder="${get('cart.save-template.placeholder')}"
        />
        <button
          class="btn ${classMap({ disabled: !savedTemplateName.length })}"
          @click=${onSaveCartTemplate}
        >
          ${get('cart.save-template.button')}
        </button>
      </div>
    </div>

    <div class="helper-csv helper-block ${classMap({ hide: !showCsvImport })}">
      <button class="helper-close" @click="${onCsvImport}">${SVG_CLOSE}</button>
      <h4>${get('cart.import.helper-title')}</h4>
      <p>${get('cart.import.helper-message')}</p>

      <table class="striped highlight">
        <thead>
          <tr>
            <th>sku</th>
            <th>qty</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>abc123</td>
            <td>10</td>
          </tr>
          <tr>
            <td>xyz123</td>
            <td>20</td>
          </tr>
        </tbody>
      </table>

      <label class="btn btn-small">
        <input
          id="csv-import-file"
          class="hide"
          type="file"
          accept="text/csv"
          @change=${handleCsvImport}
        />
        ${get('cart.import.helper-button')}
      </label>
    </div>

    <div
      class="product-list ${classMap({
        'product-updating': updatingTotals,
        'product-list--has-items': !isEmpty,
        'view-wide': wideMode,
      })}"
    >
      <span
        class="${classMap({
          'cart--loading': searchStatus === SEARCH_STATUS_SEARCHING || loadingBasket,
        })}"
      ></span>

      ${updatingTotals ? html`<spark-loading></spark-loading>` : ''}
      ${cart?.items?.map(
        item => html`
          <spark-cart-item
            item=${JSON.stringify(item)}
            quantity=${qtyUpdates[item.itemKey] ?? item.quantity}
            @qty-updated=${e => updateQty(e.detail.itemKey, e.detail.qty)}
          ></spark-cart-item>
        `
      )}
    </div>

    ${isEmpty
      ? html`
      <div class="order-empty">
        <spark-message type="success"
        title=${get('cart.empty-title')}
        message=${get('cart.empty')}
        >
      </div>
    `
      : html`
          <div
            class="order-totals ${classMap({
              loading: updatingTotals,
              'view-wide': wideMode,
            })}"
          >
            <div class="order-totals-wrap">
              <div class="row grand-total show-totals">
                <div class="col l6"><strong>${get('global.totals.sub')}</strong></div>
                <div class="col l6">
                  <strong>${moneyFormat.format(cart.subTotal.net)}</strong>
                </div>
              </div>
            </div>

            <div class="proceed-button-wrap">
              ${cart.validationErrors.length
                ? html`<spark-message
                    type="warn"
                    message=${cart.validationErrors.map(message => {
                      const messagesCopy = { ...message };
                      messagesCopy.amount = moneyFormat.format(messagesCopy.amount ?? 0);
                      return get(`cart.validation-messages.${messagesCopy.type}`, messagesCopy);
                    })}
                  >
                  </spark-message>`
                : ''}
              <button
                class="btn-large"
                @click=${onGoToShippingStep}
                ?disabled=${!!cart.validationErrors.length}
              >
                ${get('cart.proceed-to-checkout')}
              </button>
              <small>${get('cart.tax-shipping-note')}</small>
            </div>
          </div>
        `}
    ${checkoutHtml
      ? html`
          <div class="modal-overlay"></div>

          <div class="order-totals checkout-summary">
            <div class="window-close">
              <button class="link-close" @click=${onGoToCart}>
                ${get('cart.checkout.close')} ${SVG_CLOSE}
              </button>
            </div>

            <div class="summary-wrapper">
              ${checkoutLoading ? html`<spark-loading></spark-loading>` : ''}
              ${uiFlowStatus !== UI_STATE_THANKS
                ? html`
                    <div class="stepper-wrapper">
                      <a href="#" @click=${onGoToCart} class="stepper-item completed">
                        <div class="step-counter">${SVG_CIRCLE_SUCCESS}</div>
                        <div class="step-name">${get('cart.checkout.steps.step-one')}</div>
                      </a>
                      <a
                        href="#"
                        @click=${onGoToShippingStep}
                        class="stepper-item
              ${classMap({
                          completed: uiFlowStatus === UI_STATE_PAYMENT,
                          active: uiFlowStatus === UI_STATE_SHIPPING,
                        })}
              "
                      >
                        <div class="step-counter">
                          ${uiFlowStatus === UI_STATE_SHIPPING ? '2' : SVG_CIRCLE_SUCCESS}
                        </div>
                        <div class="step-name">${get('cart.checkout.steps.step-two')}</div>
                      </a>
                      <a
                        href="#"
                        @click=${onGoToPaymentStep}
                        class="stepper-item
              ${classMap({
                          active: uiFlowStatus === UI_STATE_PAYMENT,
                          pending: uiFlowStatus === UI_STATE_SHIPPING,
                        })}
              "
                      >
                        <div class="step-counter">
                          ${uiFlowStatus === UI_STATE_SHIPPING || uiFlowStatus === UI_STATE_PAYMENT
                            ? '3'
                            : SVG_CIRCLE_SUCCESS}
                        </div>
                        <div class="step-name">${get('cart.checkout.steps.step-three')}</div>
                      </a>
                      <a href="#" class="stepper-item pending">
                        <div class="step-counter">4</div>
                        <div class="step-name">${get('cart.checkout.steps.step-four')}</div>
                      </a>
                    </div>
                  `
                : ''}
              ${checkoutHtml}
            </div>
          </div>
        `
      : ''}
  `;
}

export default SparkCart;
