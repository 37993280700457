import { html } from 'lit-element';
import { virtual, useState, useRef } from 'haunted/web.js';
import { get } from '@appnest/lit-translate';
import { classMap } from 'lit-html/directives/class-map';
import { debounce } from 'throttle-debounce';
import styles from './SparkPayHokodo.scss';
import {
  SVG_ORDER_COMPLETE,
  SVG_PAYMENT_EXTERNAL_COMPANY,
  SVG_PAYMENT_EXTERNAL_SOLE,
  SVG_PAYMENT_HOKODO,
} from '../../svgs.js';
import spark from '../../spark.js';
import { newToast } from '../../utils.js';

const companySearchQuery = `
query companySearch($countryCode: String!, $search: String!) {
integrationHokodoCompaniesLookup(countryCode: $countryCode, search: $search) {
  id
  name
  address
}
}
`;
const prePaymentQuery = `
query integrationHokodoPrePayment($companyId: String!, $siteDomain: String!) {
  integrationHokodoPrePayment(companyId: $companyId, siteDomain: $siteDomain) {
    id
    orderId
    plans {
      id
      name
      paymentUrl
    }
  }
}
`;

export default virtual(updatePaymentDataCallback => {
  const COMPANY_TYPE_REGISTERED = 'registeredCompany';
  const COMPANY_TYPE_SOLE_TRADER = 'soleTrader';
  const [companyType, setCompanyType] = useState(COMPANY_TYPE_REGISTERED);
  const [companySearch, setCompanySearch] = useState('');
  const [searchResults, setSearchResults] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [countryCode, setCountryCode] = useState('GB');
  const [companyId, setCompanyId] = useState('');
  const [offers, setOffers] = useState([]);
  const [offersLoading, setOffersLoading] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState('');

  const handleCompanyTypeChange = type => {
    setCompanyType(type);
    setCompanyId('');
    setCompanySearch('');
    setSearchResults(null);
    updatePaymentDataCallback(false);
  };

  const handleUpdateCountry = e => {
    const country = e.target.value;
    setCountryCode(country);
    setCompanyId('');
    setCompanySearch('');
    setSearchResults(null);
    updatePaymentDataCallback(false);
  };

  const handleDoSearch = async search => {
    if (search === '' || countryCode === '') {
      return;
    }
    setSearchLoading(true);
    setSearchResults(null);
    try {
      const result = await spark.fetch(companySearchQuery, { countryCode, search });
      if (result.status !== 200) {
        throw new Error('failed');
      }
      const res = await result.json();
      setSearchResults(res.data.integrationHokodoCompaniesLookup);
    } catch {
      newToast('error', get('global.toast.system-error'), false);
    }
    setSearchLoading(false);
  };

  const debouncedHandleDoSearch = useRef(
    debounce(750, value => {
      setCompanySearch(value);
      handleDoSearch(value);
    })
  ).current;

  const handlePaymentDataCallback = (offerId, orderId, plan) => {
    setSelectedPlanId(plan.id);
    updatePaymentDataCallback({
      hokodoOrderId: orderId,
      hokodoOfferId: offerId,
      hokodoPlanId: plan.id,
      hokodoPaymentUrl: plan.paymentUrl,
    });
  };

  const handleChooseCompany = async selectedCompanyId => {
    setCompanyId(selectedCompanyId);
    setOffersLoading(true);
    try {
      const result = await spark.fetch(prePaymentQuery, {
        companyId: selectedCompanyId,
        siteDomain: window.location.hostname,
      });
      if (result.status !== 200) {
        throw new Error('failed');
      }
      const res = await result.json();
      setOffers(res.data.integrationHokodoPrePayment);
      setOffersLoading(false);
      // Set the first offer as selected by default
      if (res.data.integrationHokodoPrePayment.plans.length) {
        handlePaymentDataCallback(
          res.data.integrationHokodoPrePayment.id,
          res.data.integrationHokodoPrePayment.orderId,
          res.data.integrationHokodoPrePayment.plans[0]
        );
      }
    } catch {
      newToast('error', get('global.toast.system-error'), false);
    }
  };

  let content;

  // Step 1 - shows the search box for companies
  if (companyId === '') {
    const countries = [
      { value: 'GB', name: 'United Kingdom' },
      { value: '', name: 'Other' },
    ];

    const soleTraderHtml = html`
      <spark-message
        type="warn"
        message=${get('cart.checkout.payment-providers.hokodo.sole-not-eligible')}
      ></spark-message>
    `;
    const otherCountryWarningHtml = html`
      <spark-message
        type="warn"
        message=${get('cart.checkout.payment-providers.hokodo.country-not-eligible')}
      ></spark-message>
    `;

    /* eslint-disable lit-a11y/no-invalid-change-handler */
    const registeredCompanyHtml = html`
      <div class="input-field input-company-details">
        <p>${get('cart.checkout.payment-providers.hokodo.company-details')}</p>
      </div>

      <div class="input-location-wrap">
        <div class="input-field input-country">
          <select @change=${handleUpdateCountry}>
            ${countries.map(
              x => html` <option .selected=${countryCode === x.value} value=${x.value}>
                ${x.name}
              </option>`
            )}
          </select>
        </div>
        <div class="input-field input-company">
          <input
            .value=${companySearch}
            @keyup=${e => debouncedHandleDoSearch(e.target.value)}
            class="validate"
            maxlength="50"
            placeholder="${get(
              'cart.checkout.payment-providers.hokodo.company-details-placeholder'
            )}"
          />
        </div>
      </div>

      ${countryCode === '' ? otherCountryWarningHtml : ''}
      ${searchResults !== null && searchResults.length
        ? html` <div class="input-field">
            <p>${get('cart.checkout.payment-providers.hokodo.search-results')}</p>
            <div class="radio-wrap search-results">
              ${searchResults.map(
                x => html`<label>
                  <button @click=${() => handleChooseCompany(x.id)}>
                    <h4>${x.name}</h4>
                    <span>${x.address}</span>
                  </button>
                </label>`
              )}
            </div>
          </div>`
        : ''}
      ${searchResults !== null && !searchResults.length
        ? html`
            <spark-message
              type="warn"
              message=${get('cart.checkout.payment-providers.hokodo.no-search-results')}
            >
            </spark-message>
          `
        : ''}
      ${searchLoading
        ? html`<div class="hokodo-loading"><spark-loading size="small"></spark-loading></div>`
        : ''}
    `;
    /* eslint-enable lit-a11y/no-invalid-change-handler */

    content = html`
      <div id="payment-external-step-1">
        <div class="input-field">
          <div class="radio-wrap radio-half-width">
            <label
              class="${classMap({ selected: companyType === COMPANY_TYPE_REGISTERED })}"
              @click=${() => handleCompanyTypeChange(COMPANY_TYPE_REGISTERED)}
              @keyDown=${() => handleCompanyTypeChange(COMPANY_TYPE_REGISTERED)}
            >
              <div class="radio-details">
                <input
                  name="companyType"
                  value="registeredCompany"
                  type="radio"
                  .selected=${companyType === COMPANY_TYPE_REGISTERED}
                />
                <em>${SVG_PAYMENT_EXTERNAL_COMPANY}</em>
                <span>${get('cart.checkout.payment-providers.hokodo.company-registered')}</span>
              </div>
            </label>
            <label
              class="${classMap({ selected: companyType === COMPANY_TYPE_SOLE_TRADER })}"
              @click=${() => handleCompanyTypeChange(COMPANY_TYPE_SOLE_TRADER)}
              @keydown=${() => handleCompanyTypeChange(COMPANY_TYPE_SOLE_TRADER)}
            >
              <div class="radio-details">
                <input
                  name="companyType"
                  value="soleTrader"
                  type="radio"
                  .selected=${companyType === COMPANY_TYPE_SOLE_TRADER}
                />
                <em>${SVG_PAYMENT_EXTERNAL_SOLE}</em>
                <span>${get('cart.checkout.payment-providers.hokodo.company-sole')}</span>
              </div>
            </label>
          </div>
        </div>

        ${companyType === COMPANY_TYPE_SOLE_TRADER ? soleTraderHtml : registeredCompanyHtml}
      </div>
    `;
  } else if (offersLoading) {
    content = html`<div class="hokodo-loading"><spark-loading size="small"></spark-loading></div>`;
  } else if (offers.plans.length) {
    content = html`
      <div id="payment-external-step-2">
        <div class="input-field input-select-plan">
          <button
            class="previous btn-reset btn-link"
            @click=${() => handleCompanyTypeChange(COMPANY_TYPE_REGISTERED)}
          >
            ${get('cart.checkout.payment-providers.hokodo.link-previous')}
          </button>
          <p>${get('cart.checkout.payment-providers.hokodo.payment-plan-select')}</p>

          <spark-message
            type="success"
            message=${get('cart.checkout.payment-providers.hokodo.eligible')}
          >
          </spark-message>

          <div class="radio-wrap radio-full-width">
            ${offers.plans.map(
              plan => html` <label
                class="${classMap({ selected: selectedPlanId === plan.id })}"
                @click=${() => handlePaymentDataCallback(offers.id, offers.orderId, plan)}
                @keydown=${() => handlePaymentDataCallback(offers.id, offers.orderId, plan)}
              >
                <div class="radio-status">
                  ${selectedPlanId === plan.id ? SVG_ORDER_COMPLETE : ''}
                </div>
                <div class="radio-details">
                  <input type="radio" />
                  <span
                    >${get(
                      `cart.checkout.payment-providers.hokodo.payment-plans.${plan.name}`
                    )}</span
                  >
                  <em><i>${plan.name.match(/\d+/g)}</i></em>
                </div>
              </label>`
            )}
          </div>
        </div>
      </div>
    `;
  } else {
    content = html`
      <div class="input-field input-no-plan">
        <button class="previous btn-reset btn-link" @click=${() =>
          handleCompanyTypeChange(COMPANY_TYPE_REGISTERED)}>
          ${get('cart.checkout.payment-providers.hokodo.link-previous')}
        </button>
        <p>${get('cart.checkout.payment-providers.hokodo.payment-no-plan-available')}</p>

        <spark-message type="warn" message=${get(
          'cart.checkout.payment-providers.hokodo.not-eligible'
        )} \>
      </div>
    `;
  }

  return html`
    <style>
      ${styles}
    </style>

    <div class="payment-external">
      <div class="payment-header">
        ${SVG_PAYMENT_HOKODO}
        <span>
          ${get('cart.checkout.payment-providers.hokodo.header-text')}
          <a href="/spark-redirect/hokodo-bnpl" target="_blank"
            >${get('cart.checkout.payment-providers.hokodo.header-link')}</a
          >
        </span>
      </div>

      <div class="item-content">${content}</div>
    </div>
  `;
});
