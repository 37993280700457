import { html } from 'lit-element';
import { useState, useEffect } from 'haunted/web.js';
import { get } from '@appnest/lit-translate';
import { classMap } from 'lit-html/directives/class-map';
import styles from './SparkProductCard.scss';
import SparkQtySelect from './SparkQtySelect.js';
import spark, { LS_USER_DATA } from '../spark.js';
import {
  calculateCartPriceForProduct,
  calculateFromPrice,
  createCurrencyFormatter,
  getPackSize,
  useLocalStorage,
} from '../utils.js';
import { EVENT_UPDATE_PRICE_DATA } from '../priceData.js';
import { SVG_CIRCLE_ERROR, SVG_CIRCLE_HISTORY } from '../svgs.js';

function SparkProductCard({ parentId }) {
  const [user] = useLocalStorage(LS_USER_DATA, null);
  const [productState, setProductState] = useState(null);
  const [addToCartLoading, setAddToCartLoading] = useState(false);
  const [qtyTracking, setQtyTracking] = useState({});
  const [productOptions, setProductOptions] = useState([]);
  const [variant, setVariant] = useState(null);

  // Function is to update the selected variant for the add form!
  const updateSelectedVariant = (productData, options) => {
    let selectedVariant = null;
    if (options.length > 0) {
      const selectedOptions = options.map(x => {
        return { name: x.name, value: x.values.find(y => y.selected)?.name || null };
      });
      for (const searchVariant of productData.variants) {
        if (
          selectedOptions.every(x =>
            searchVariant.options.find(y => y.group === x.name && y.value === x.value)
          )
        ) {
          selectedVariant = searchVariant;
          break;
        }
      }
    } else if (productData) {
      [selectedVariant] = productData.variants;
    }
    setVariant(selectedVariant);
    setQtyTracking(
      selectedVariant
        ? {
            id: selectedVariant.id,
            sku: selectedVariant.sku,
            packSize: getPackSize(selectedVariant),
            qty: getPackSize(selectedVariant),
          }
        : {}
    );
  };

  const setData = state => {
    setProductState(state);
    if (!state.data) {
      setProductOptions([]);
      return;
    }
    const productData = state.data;
    /**
     * Setup product options dropdowns
     */
    const options = [];
    // Sort the variants by position but first for the product card, always show in stock items first
    productData.variants.sort((a, b) => {
      if (a.stockStatus !== 'out_of_stock' && b.stockStatus === 'out_of_stock') {
        return -1;
      }
      if (b.stockStatus !== 'out_of_stock' && a.stockStatus === 'out_of_stock') {
        return 1;
      }
      return a.position - b.position;
    });
    const initiallySelectedPosition = productData.variants[0].position;
    // Now place the order back into the correct order
    productData.variants.sort((a, b) => a.position - b.position);
    productData.variants.forEach(variantX => {
      variantX.options.forEach(option => {
        let foundOptionGroup = options.find(o => o.name === option.group);
        if (!foundOptionGroup) {
          foundOptionGroup = {
            name: option.group,
            values: [],
            dropdown: true, // NOTE: this is different to the PDP as we'll always have a dropdown
          };
          options.push(foundOptionGroup);
        }
        let foundOptionGroupValue = foundOptionGroup.values.find(o => o.name === option.value);
        if (!foundOptionGroupValue) {
          foundOptionGroupValue = {
            name: option.value,
            selected: variantX.position === initiallySelectedPosition,
          };
          foundOptionGroup.values.push(foundOptionGroupValue);
        } else if (variantX.position === initiallySelectedPosition) {
          foundOptionGroupValue.selected = true;
        }
      });
    });
    setProductOptions(options);
    updateSelectedVariant(state.data, options);
  };

  // Trigger a fetch on user or parentId updates
  useEffect(() => {
    if (!user || !parentId) {
      // todo handle user logout here, i.e. user is now null
      return;
    }
    setData(window.spark.priceData.fetch(parentId));
  }, [user, parentId]);

  // Handle messaging for updates via the message bus
  useEffect(() => {
    const handleDataUpdate = event => {
      if (event.detail.parentId === parentId) {
        setData(event.detail.state);
      }
    };
    window.spark.msgBus.addEventListener(EVENT_UPDATE_PRICE_DATA, handleDataUpdate);
    return () => {
      window.spark.msgBus.removeEventListener(EVENT_UPDATE_PRICE_DATA, handleDataUpdate);
    };
  }, []);

  const onAddToBasketAction = async () => {
    setAddToCartLoading(true);
    await spark.updateCart(
      { products: [{ sku: qtyTracking.sku, absoluteQuantity: qtyTracking.qty }] },
      false
    );
    // Reset product quantity
    setQtyTracking({ ...qtyTracking, qty: qtyTracking.packSize });
    setAddToCartLoading(false);
  };
  const onQtyUpdated = e => setQtyTracking({ ...qtyTracking, qty: e.detail.qty });
  const onProductOptionChange = e => {
    const update = productOptions;
    const optionGroup = update.find(group => group.name === e.target.name);
    optionGroup.values = optionGroup.values.map(value => {
      const updatedValue = value;
      updatedValue.selected = false;
      return updatedValue;
    });
    const optionValue = optionGroup.values.find(value => e.target.value === value.name);
    optionValue.selected = true;
    setProductOptions(update);
    updateSelectedVariant(productState.data, update);
  };

  if (!user) {
    return html`<!-- Spark User Logged Out -->`;
  }
  if (!productState || productState.loading) {
    return html`
      <style>
        ${styles}
      </style>

      <div class="loading-prices"></div>
    `;
  }
  if (productState.notFound) {
    return html`<!-- Spark Product not found -->`;
  }
  if (productState.error || !variant) {
    return html`
      <style>
        ${styles}
      </style>
      Error
    `;
  }
  const [noOfPrices, fromPrice, currencyCode] = calculateFromPrice(productState.data);
  const moneyFormat = createCurrencyFormatter(currencyCode);

  const pricingHtml = html`
    <div class="product-price-wrapper">
      ${fromPrice
        ? html`<p class="product-price">
            ${get('pdp.price.from-prefix')}
            ${noOfPrices > 1 ? get('product-card.price.from') : ''}${moneyFormat.format(fromPrice)}
          </p>`
        : ''}
      ${variant.rrp
        ? html`<p class="product-rrp-price">
            ${get('product-card.price.rrp')}${moneyFormat.format(variant.rrp)}
          </p>`
        : ''}
    </div>
  `;
  /* eslint-disable lit-a11y/no-invalid-change-handler */
  const productOptionHtml = html`
    ${productOptions.length
      ? html`<div class="input-field product-variant-select">
          ${productOptions.map(
            optionGroup => html`<select name=${optionGroup.name} @change=${onProductOptionChange}>
              ${optionGroup.values.map(
                option => html`<option .selected=${option.selected}>${option.name}</option>`
              )}
            </select>`
          )}
        </div>`
      : html`<div class="input-field product-variant-select"></div>`}
  `;
  /* eslint-enable lit-a11y/no-invalid-change-handler */
  let formHtml = null;
  if (variant) {
    const oos = variant.stockStatus === 'out_of_stock';
    if (variant.price && !oos) {
      const cartPrice = calculateCartPriceForProduct(variant, qtyTracking.qty).total ?? 0;
      formHtml = html`
        <div class="product-purchase-listing">
          <div class="product-quantity-wrap">
            ${SparkQtySelect(qtyTracking.qty, qtyTracking.packSize, qtyTracking.id, onQtyUpdated)}
          </div>
          <div class="button-purchase button-inline">
            <button
              class="btn-small no-margin ${classMap({
                'button-purchase--btn-loading': addToCartLoading,
              })}"
              @click=${onAddToBasketAction}
              ?disabled=${addToCartLoading}
            >
              <span class="purchase-amount"
                >${get('product-card.add-to-order.text')} (${moneyFormat.format(cartPrice)})</span
              >
              ${addToCartLoading ? html`<span class="loading-text"></span>` : ``}
            </button>
          </div>
          ${variant.stockStatus === 'backorder'
            ? html`
                <p class="stock-status back-order">
                  ${SVG_CIRCLE_HISTORY}
                  <span>${get('product-card.messaging.back-order')}</span>
                </p>
              `
            : ''}
        </div>
      `;
    } else {
      formHtml = html`
        <p class="stock-status ${oos ? 'out-of-stock' : 'un-sellable'}">
          ${SVG_CIRCLE_ERROR}
          <span> ${get(`product-card.messaging.${oos ? 'out-of-stock' : 'un-sellable'}`)} </span>
        </p>
      `;
    }
  } else {
    formHtml = html`Please select an option`;
  }

  return html`
    <style>
      ${styles}
    </style>

    <div class="product-card">${pricingHtml} ${productOptionHtml} ${formHtml}</div>
  `;
}

export default SparkProductCard;
