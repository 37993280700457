import { html } from 'haunted/web.js';

export const SVG_EXPAND = html`<svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M9.29 13.29 4 18.59V17a1 1 0 1 0-2 0v4a1 1 0 0 0 .08.38 1 1 0 0 0 .54.54c.12.051.25.078.38.08h4a1 1 0 0 0 0-2H5.41l5.3-5.29a1.004 1.004 0 1 0-1.42-1.42ZM5.41 4H7a1 1 0 0 0 0-2H3a1 1 0 0 0-.38.08 1 1 0 0 0-.54.54A1 1 0 0 0 2 3v4a1 1 0 0 0 2 0V5.41l5.29 5.3a1 1 0 0 0 1.639-.325 1 1 0 0 0-.219-1.095L5.41 4ZM21 16a1 1 0 0 0-1 1v1.59l-5.29-5.3a1.004 1.004 0 1 0-1.42 1.42l5.3 5.29H17a1 1 0 0 0 0 2h4c.13-.002.26-.029.38-.08a1 1 0 0 0 .54-.54c.051-.12.078-.25.08-.38v-4a1 1 0 0 0-1-1Zm.92-13.38a1 1 0 0 0-.54-.54A1 1 0 0 0 21 2h-4a1 1 0 1 0 0 2h1.59l-5.3 5.29a1 1 0 0 0 .325 1.639 1 1 0 0 0 1.095-.219L20 5.41V7a1 1 0 0 0 2 0V3a1.001 1.001 0 0 0-.08-.38Z"
  />
</svg>`;

export const SVG_CLOSE = html`<svg
  width="20"
  height="20"
  fill="#555"
  viewBox="0 0 20 20"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
    clip-rule="evenodd"
  ></path>
</svg>`;

export const SVG_ORDER_COMPLETE = html`<svg
  width="50"
  height="50"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <circle cx="25" cy="25" r="25" fill="#28AB76" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="m33 18 2 2-12 12h-1l-7-7 2-2 6 4 10-9Z"
    fill="#fff"
  />
</svg>`;

export const SVG_CIRCLE_ERROR = html`<svg
  width="32"
  height="32"
  fill="none"
  viewBox="0 0 32 32"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M16 3C9 3 3 9 3 16s6 13 13 13 13-6 13-13S23 3 16 3Zm0 20-1-2 1-1 1 1-1 2Zm1-7-1 1-1-1v-5l1-2 1 2v5Z"
    fill="currentColor"
  />
</svg>`;

export const SVG_CIRCLE_HISTORY = html`<svg
  aria-hidden="true"
  data-prefix="fas"
  data-icon="history"
  class="svg-inline--fa fa-history fa-w-16"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 512 512"
>
  <path
    fill="currentColor"
    d="M504 256a249 249 0 0 1-404 193c-11-9-12-25-1-36l11-11c8-8 22-9 32-2a184 184 0 1 0-12-278l51 51c10 10 3 27-12 27H24c-9 0-16-7-16-16V39c0-15 17-22 27-12l50 50a247 247 0 0 1 419 179zm-181 78 10-12c8-11 6-26-4-34l-41-32V152c0-13-11-24-24-24h-16c-13 0-24 11-24 24v136l65 51c11 8 26 6 34-5z"
  />
</svg>`;

export const SVG_CIRCLE_SUCCESS = html`<svg
  width="50"
  height="50"
  viewBox="0 0 50 50"
  xmlns="http://www.w3.org/2000/svg"
>
  <circle cx="25" cy="25" r="25" fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M32.5373 18L35 20.3333L22.6867 32L22.5366 31.8576L22.388 32L15 25L17.4627 22.6667L22.5366 27.4755L32.5373 18Z"
    fill="white"
  />
</svg>`;

export const SVG_LINE_DIVIDER = html`<svg
  width="10"
  height="18"
  fill="none"
  viewBox="0 0 10 18"
  xmlns="http://www.w3.org/2000/svg"
>
  <path d="m1 1 8 8-8 8" stroke="#000" stroke-linecap="square" />
</svg>`;

export const SVG_ARROW_DOWN = html`<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 20 20"
  fill="currentColor"
  aria-hidden="true"
>
  <path
    fill-rule="evenodd"
    d="M5.3 7.3a1 1 0 0 1 1.4 0l3.3 3.3 3.3-3.3a1 1 0 1 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 0-1.4z"
    clip-rule="evenodd"
  />
</svg>`;

export const SVG_ARROW_RIGHT = html`<svg
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
  stroke="currentColor"
>
  <path d="M19 12H5M12 19l-7-7 7-7" />
</svg>`;

export const SVG_CLEAR = html`<svg
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
  viewBox="0 0 24 24"
  stroke="currentColor"
>
  <path
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2.5"
    d="M18.4 18.4A9 9 0 0 0 5.6 5.6m12.8 12.8A9 9 0 0 1 5.6 5.6m12.8 12.8L5.6 5.6"
  />
</svg>`;

export const SVG_SAVE = html`<svg
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
  viewBox="0 0 24 24"
  stroke="currentColor"
>
  <path
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2.5"
    d="M8 16H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2m-6 12h8a2 2 0 0 0 2-2v-8a2 2 0 0 0-2-2h-8a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2z"
  />
</svg>`;

export const SVG_IMPORT = html`<svg
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
  viewBox="0 0 24 24"
  stroke="currentColor"
>
  <path
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2"
    d="M9 5H7a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-2M9 5a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2M9 5a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2m-3 7h3m-3 4h3m-6-4h0m0 4h0"
  />
</svg>`;

export const SVG_DELETE = html`<svg
  width="20"
  height="20"
  fill="none"
  viewBox="0 0 20 20"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M2.5 5h15M6.7 5V3.3a1.7 1.7 0 0 1 1.6-1.6h3.4a1.7 1.7 0 0 1 1.6 1.6V5H6.7Zm9.1 0v11.7a1.7 1.7 0 0 1-1.6 1.6H5.8a1.7 1.7 0 0 1-1.6-1.6V5h11.6ZM8.3 9.2v5M11.7 9.2v5"
    stroke="#000"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>`;

export const SVG_LOGOUT = html`<svg
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M14.37 10.32a.75.75 0 0 0-1 .34 6 6 0 1 1 0-5.32.75.75 0 0 0 1.35-.68 7.5 7.5 0 1 0 0 6.68.75.75 0 0 0-.35-1.02Zm.38-3.07h-7.2l1.73-1.72a.75.75 0 1 0-1.06-1.06l-3 3a.75.75 0 0 0-.16.25.75.75 0 0 0 0 .57c.04.09.09.17.16.24l3 3A.75.75 0 0 0 9.5 11a.75.75 0 0 0-.22-.53L7.56 8.75h7.19a.75.75 0 1 0 0-1.5Z"
    fill="#000"
    fill-opacity=".5"
  />
</svg>`;

export const SVG_QUICK_ORDER = html`<svg
  height="25"
  width="25"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  stroke-width="2"
  stroke-linecap="round"
  stroke-linejoin="round"
  stroke="currentColor"
>
  <circle cx="10.5" cy="10.5" r="7.5" />
  <path d="m21 21-5-5" />
</svg>`;

export const SVG_TAB_ORDER = html`<svg
  width="20"
  height="21"
  viewBox="0 0 20 26"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M18.8 6.8H15V5.5a5 5 0 0 0-10 0v1.3H1.2A1.3 1.3 0 0 0 0 8v13.8a3.8 3.8 0 0 0 3.8 3.7h12.4a3.8 3.8 0 0 0 3.8-3.8V8a1.3 1.3 0 0 0-1.3-1.3ZM7.4 5.5a2.5 2.5 0 1 1 5 0v1.3h-5V5.5Zm10 16.3a1.3 1.3 0 0 1-1.3 1.2H3.8a1.3 1.3 0 0 1-1.3-1.3V9.3H5v1.2a1.3 1.3 0 0 0 2.5 0V9.2h5v1.3a1.3 1.3 0 0 0 2.5 0V9.2h2.5v12.6Z"
    fill="#000"
  />
</svg>`;

export const SVG_TAB_ACCOUNT = html`<svg
  width="20"
  height="21"
  fill="none"
  viewBox="0 0 20 21"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M13.6 10.5a6 6 0 0 0 .3-8.7A6 6 0 0 0 3.6 6a6 6 0 0 0 2 4.5A9.7 9.7 0 0 0 0 19.3a1.2 1.2 0 0 0 2.4 0 7.2 7.2 0 1 1 14.5 0 1.2 1.2 0 0 0 2.4 0 9.7 9.7 0 0 0-5.7-8.8Zm-4-.8a3.6 3.6 0 1 1 0-7.3 3.6 3.6 0 0 1 0 7.3Z"
    fill="#000"
  />
</svg>`;

export const SVG_PAYMENT_CREDIT = html`<svg
  width="29"
  height="37"
  viewBox="0 0 29 37"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M9.1 18.5H20 9Zm0 7.2H20 9Zm14.4 9h-18A3.6 3.6 0 0 1 2 31V6a3.6 3.6 0 0 1 3.5-3.6h10c.5 0 1 .2 1.3.5l9.7 9.7c.4.3.5.8.5 1.3V31a3.6 3.6 0 0 1-3.5 3.6Z"
  />
</svg>`;

export const SVG_PAYMENT_CARD = html`<svg
  width="41"
  height="33"
  fill="none"
  viewBox="0 0 41 33"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M2.1 12.4H39 2Zm8.2 10.2h2-2Zm10.2 0h2-2ZM8.2 30.8h24.6a6.1 6.1 0 0 0 6-6.1V8.3a6.1 6.1 0 0 0-6-6H8.3a6.1 6.1 0 0 0-6.2 6v16.4a6.1 6.1 0 0 0 6.2 6Z"
  />
</svg>`;

export const SVG_PAYMENT_QUOTE = html`<svg
  xmlns="http://www.w3.org/2000/svg"
  width="20"
  height="24"
  viewBox="0 0 20 24"
  fill="none"
>
  <path
    d="M9 5H7a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-2M9 5a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2M9 5a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2m-3 7h3m-3 4h3m-6 0"
  />
</svg>`;

export const SVG_PAYMENT_BNPL = html`<svg
  xmlns="http://www.w3.org/2000/svg"
  class="h-6 w-6"
  fill="none"
  viewBox="0 0 24 24"
  stroke="currentColor"
>
  <path
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2"
    d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0z"
  />
</svg>`;

export const SVG_PAYMENT_HOKODO = html`<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 123 32"
  width="123"
  height="32"
>
  <g fill="none">
    <path
      fill="#000"
      d="M14 4v8.2c0 1.3-1.1 2.3-2.4 2.3H5.4c-1.3 0-2.4-1-2.4-2.3V4H0v8.2C0 13.7.6 15 1.7 16A5.3 5.3 0 0 0 0 19.8V28h3v-8.2c0-1.3 1.1-2.3 2.4-2.3h6.2c1.3 0 2.4 1 2.4 2.3V28h3v-8.2c0-1.5-.6-2.8-1.7-3.8a5.3 5.3 0 0 0 1.7-3.8V4h-3zm19.6-1h-6.2c-3 0-5.4 2.4-5.4 5.3v14.4c0 3 2.4 5.3 5.4 5.3h6.2c3 0 5.4-2.4 5.4-5.3V8.3c0-3-2.4-5.3-5.4-5.3zM36 22.7c0 1.3-1.1 2.3-2.4 2.3h-6.2c-1.3 0-2.4-1-2.4-2.3V8.3C25 7 26.1 6 27.4 6h6.2C34.9 6 36 7 36 8.3v14.4zM75.6 3h-6.2c-3 0-5.4 2.4-5.4 5.3v14.4c0 3 2.4 5.3 5.4 5.3h6.2c3 0 5.4-2.4 5.4-5.3V8.3c0-3-2.4-5.3-5.4-5.3zM78 22.7c0 1.3-1.1 2.3-2.4 2.3h-6.2c-1.3 0-2.4-1-2.4-2.3V8.3C67 7 68.1 6 69.4 6h6.2C76.9 6 78 7 78 8.3v14.4zM117.6 3h-6.2c-3 0-5.4 2.4-5.4 5.3v14.4c0 3 2.4 5.3 5.4 5.3h6.2c3 0 5.4-2.4 5.4-5.3V8.3c0-3-2.4-5.3-5.4-5.3zm2.4 19.7c0 1.3-1.1 2.3-2.4 2.3h-6.2c-1.3 0-2.4-1-2.4-2.3V8.3c0-1.3 1.1-2.3 2.4-2.3h6.2c1.3 0 2.4 1 2.4 2.3v14.4zM96.6 3H85v25h11.6c3 0 5.4-2.4 5.4-5.3V8.3c0-3-2.4-5.3-5.4-5.3zM99 22.7c0 1.3-1.1 2.3-2.4 2.3H88V6h8.6C97.9 6 99 7 99 8.3v14.4zm-48.4-6.5h-1.4c-1.2 0-2.3-1-2.3-2.3V4H44v24h3v-9.3a5.1 5.1 0 0 0 2.2.5H52c1.6.3 2.2 1.1 3.8 4l.7 1.1a185.1 185.1 0 0 0 2 3.7l1.3-.8 1.3-.8-2-3.6c-2.7-4.7-3.8-6.5-8.4-6.6z"
    />
    <path fill="#C98A29" d="M0 31h17v1H0zM44 0h17v1H44z" />
    <path fill="#000" d="m60 6-3-1-4 9.9c1.2.2 2.2.5 3 1.1l4-10z" />
  </g>
</svg>`;

export const SVG_PAYMENT_EXTERNAL_SOLE = html`<svg
  xmlns="http://www.w3.org/2000/svg"
  class="h-6 w-6"
  fill="none"
  viewBox="0 0 24 24"
  stroke="currentColor"
>
  <path
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2"
    d="M5.1 17.8A14 14 0 0 1 12 16c2.5 0 4.8.7 6.9 1.8M15 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0zm6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0z"
  />
</svg>`;

export const SVG_PAYMENT_EXTERNAL_COMPANY = html`<svg
  xmlns="http://www.w3.org/2000/svg"
  class="h-6 w-6"
  fill="none"
  viewBox="0 0 24 24"
  stroke="currentColor"
>
  <path
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2"
    d="M19 21V5a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v5m-4 0h4"
  />
</svg>`;

export const SVG_PLUS = html`<svg
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
  viewBox="0 0 24 24"
  stroke="currentColor"
>
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
</svg>`;

export const SVG_MINUS = html`<svg
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
  viewBox="0 0 24 24"
  stroke="currentColor"
>
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4" />
</svg>`;

export const SVG_WARN = html`<svg
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 61 56"
>
  <path
    d="M30 22v6-6Zm0 19h1-1ZM9 53h43c5 0 8-5 6-9L36 7c-2-5-9-5-11 0L3 44c-2 4 1 9 6 9Z"
    stroke="#FF9841"
    stroke-width="5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>`;

export const SVG_EDIT = html`<svg
  xmlns="http://www.w3.org/2000/svg"
  width="20"
  height="20"
  fill="none"
  viewBox="0 0 24 24"
  stroke="currentColor"
>
  <path
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-width="2"
    stroke="#000"
    d="M11 5H6a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h11a2 2 0 0 0 2-2v-5m-1.4-9.4a2 2 0 1 1 2.8 2.8L11.8 15H9v-2.8l8.6-8.6z"
  />
</svg>`;
