import 'regenerator-runtime/runtime.js';
import { component } from 'haunted/web.js';
import { registerTranslateConfig, use } from '@appnest/lit-translate';
import deepmerge from 'deepmerge';
import en from './i18n/en.json';
import fr from './i18n/fr.json';
import de from './i18n/de.json';
import es from './i18n/es.json';
import sv from './i18n/sv.json';
import it from './i18n/it.json';
import pt from './i18n/pt.json';
import elLang from './i18n/el.json';
import spark from './spark.js';
import SparkAccount from './components/SparkAccount.js';
import SparkCart from './components/SparkCart.js';
import SparkCartItem from './components/SparkCartItem.js';
import SparkDrawer from './components/SparkDrawer.js';
import SparkLogin from './components/SparkLogin.js';
import SparkLoading from './components/SparkLoading.js';
import SparkMessage from './components/SparkMessage.js';
import SparkPDP from './components/SparkPDP.js';
import SparkToast from './components/SparkToast.js';
import { addComponentToPage, applyBaseCSS } from './utils.js';
import SparkProductPrice from './components/SparkProductPrice.js';
import SparkProductCard from './components/SparkProductCard.js';

customElements.define('spark-loading', component(SparkLoading, { observedAttributes: ['size'] }));
customElements.define('spark-toast', component(SparkToast));
customElements.define(
  'spark-message',
  component(SparkMessage, { observedAttributes: ['title', 'message', 'type'] })
);
customElements.define(
  'spark-drawer',
  component(SparkDrawer, { observedAttributes: ['initial-tab'] })
);
customElements.define('spark-cart', component(SparkCart));
customElements.define('spark-account', component(SparkAccount));
customElements.define(
  'spark-cart-item',
  component(SparkCartItem, {
    observedAttributes: ['item', 'quantity'],
  })
);
customElements.define('spark-login', component(SparkLogin));
customElements.define('spark-pdp', component(SparkPDP, { observedAttributes: ['parent-id'] }));
customElements.define(
  'spark-product-card',
  component(SparkProductCard, { observedAttributes: ['parent-id'] })
);
customElements.define(
  'spark-product-price',
  component(SparkProductPrice, { observedAttributes: ['parent-id'] })
);

let defaultOptions = {
  sparkDomain: 'app.sparklayer.io',
  platform: 'base',
  accountButtonSelectors: '[href="/account"], [data-spark-link=account]',
  logoutButtonSelectors: '[href="/account/logout"], [data-spark-link=logout]',
  cartButtonSelectors: '[href="/cart"], [data-spark-link=cart]',
  loginButtonSelectors: '[href="/spark-b2b-login"], [data-spark-link=login]',
  display: {
    customerReferenceRequired: false,
    stock: {
      show: false,
      low: 25,
      last: 5,
      max: 10000, // todo long term this needs to be API driven
    },
  },
  useSparkLogin: true,
  authLogoutUri: null,
  termsAndConditionsLink: '/terms-of-service',
  productLink: '/:product-slug:',
  siteId: '',
  language: 'en',
  translations: {},
  showTranslations: false,
  auth: {},
  accountRedirect: {
    // urlRegex: /\/account/g,
    // goTo: '/',
  },
  cartRedirect: {
    // urlRegex: /\/account/g,
    // goTo: '/',
  },
};
// eslint-disable-next-line default-case
switch (window.sparkOptions.platform) {
  case 'shopify':
    defaultOptions = Object.assign(defaultOptions, {
      useSparkLogin: false,
      authLogoutUri: '/account/logout',
      accountRedirect: {
        urlRegex: /\/account((?!\/[login|reset|activate]).*)/g,
        goTo: '/',
      },
      cartRedirect: {
        urlRegex: /\/cart/g,
        goTo: '/',
      },
      termsAndConditionsLink: '/pages/terms-and-conditions',
      productLink: '/products/:product-slug:',
    });
}
const options = deepmerge(defaultOptions, window.sparkOptions ?? {});
if (options.siteId === '') {
  throw new Error('siteId must be configured within options, sparkLayer disabled.');
}
let translationLoader = () => {};
if (!options.showTranslations) {
  const translationStrings = deepmerge(
    { fr, es, en, de, sv, el: elLang, it, pt },
    options.translations || {}
  );
  translationLoader = langKey => translationStrings[langKey];
}
registerTranslateConfig({
  loader: translationLoader,
  empty: key => {
    // Fallback to english when required
    const parts = key.split('.');
    let string = en;
    while (string != null && parts.length > 0) {
      string = string[parts.shift()];
    }
    return string != null ? string.toString() : key;
  },
});
use(options.language);
function onReady(isLoggedIn) {
  addComponentToPage('spark-toast');
  applyBaseCSS(isLoggedIn);
  /**
   * For 3r party e-commerce platforms, we need to ensure the user cannot view the account pages but they need to be able
   * to access forgotten password pages and reset pages
   * Example shopify pages:
   * /account/reset/2435562930260/fb336c27a1e13622be34d72539a38173-1611753855
   * /account/login
   */
  if (options.accountRedirect.goTo && options.accountRedirect.urlRegex && isLoggedIn) {
    // eslint-disable-next-line no-restricted-globals
    if (location.pathname.match(options.accountRedirect.urlRegex)) {
      window.location.href = `${options.accountRedirect.goTo}#spark-account`;
    }
  }
  if (options.cartRedirect.goTo && options.cartRedirect.urlRegex && isLoggedIn) {
    // eslint-disable-next-line no-restricted-globals
    if (location.pathname.match(options.cartRedirect.urlRegex)) {
      window.location.href = `${options.cartRedirect.goTo}#spark-cart`;
    }
  }
  // eslint-disable-next-line no-restricted-globals
  if (isLoggedIn && location.hash === '#spark-account') {
    addComponentToPage('spark-drawer', { 'initial-tab': 'account' });
  }
  // eslint-disable-next-line no-restricted-globals
  if (isLoggedIn && location.hash === '#spark-cart') {
    addComponentToPage('spark-drawer', { 'initial-tab': 'cart' });
  }

  for (const [initialTab, selectors] of Object.entries({
    cart: options.cartButtonSelectors,
    account: options.accountButtonSelectors,
  })) {
    document.querySelectorAll(selectors).forEach(el => {
      el.addEventListener('click', async e => {
        if (await window.spark.isLoggedIn()) {
          e.stopImmediatePropagation();
          e.preventDefault();
          e.stopPropagation();
          addComponentToPage('spark-drawer', { 'initial-tab': initialTab });
        }
        return false;
      });
    });
  }
  /**
   * Add logout selectors to trigger only if the user is logged in
   */
  document.querySelectorAll(options.logoutButtonSelectors).forEach(el => {
    el.addEventListener('click', e => {
      e.stopImmediatePropagation();
      e.preventDefault();
      e.stopPropagation();
      window.spark.logout();
      return false;
    });
  });
  /**
   * For sites using spark layer auth, enable the spark-login popup on selectors
   */
  if (options.useSparkLogin) {
    document.querySelectorAll(options.loginButtonSelectors).forEach(el => {
      el.addEventListener('click', async e => {
        e.stopImmediatePropagation();
        e.preventDefault();
        e.stopPropagation();
        if (await window.spark.isLoggedIn()) {
          addComponentToPage('spark-drawer', { 'initial-tab': 'account' });
        } else {
          addComponentToPage('spark-login');
        }
        return false;
      });
    });
  }
}

spark.init(options).then(isLoggedIn => {
  if (document.readyState !== 'loading') {
    onReady(isLoggedIn);
  } else {
    document.addEventListener('DOMContentLoaded', () => onReady(isLoggedIn));
  }
});
window.spark = spark;
