const addressQuery = () => `
defaultBillingAddressId
defaultShippingAddressId
addresses {
  id
  description
}
`;

const variantFields = () => `
id
stockStatus
${window.spark.options.display.stock.show ? 'stockQty' : ''}
cartImageUrl
position
sku
slug
name
price {
  taxRate
  net
  gross
  currencyCode
}
rrp
priceBreaks {
  quantity
  price {
    taxRate
    net
    gross
    currencyCode
  }
}
settings {
  key
  value
}
options {
  group
  value
}
`;

export const productsQuery = parentIds => {
  return [
    'query {', // todo convert the below into a parametrised query
    parentIds
      .map((x, i) => {
        return `product${i}: product(id: "${x}") {
        id
        variants {
          ${variantFields()}
        }
      }`;
      })
      .join('\n'),
    '}',
  ].join('');
};

export const quickOrderQuery = () => `
query FetchQuickOrderData($sku: String!) {
  variant(sku: $sku) {
    ${variantFields()}
  }
}
`;

const cartFields = () => `
# Common Cart Fields start
  cartUuid
  items {
    # Cart Item
    itemKey
    quantity
    lineTotal {
      net
      gross
      currencyCode
    }
    unitTotal {
      net
      gross
      currencyCode
    }
    # Variant Fields
    ${variantFields()}
  }
  validationErrors {
    type
    ... on CartValidationErrorMinimumOrderTotals {
      amount
    }
    ... on CartValidationErrorMaximumParentQuantity {
      maximum
      productName
      parentSKU
    }
    ... on CartValidationErrorMinimumParentQuantity {
      minimum
      productName
      parentSKU
    }
  }
  subTotal {
    net
    gross
    currencyCode
  }
  deliveryCharge {
    net
    gross
    currencyCode
  }
  total {
    net
    gross
    currencyCode
  }
  customerAddresses {
    id
    description
  }
  deliveryAddressId
  customerReference
# Common Cart Fields end
`;
export const updateCartParamsDefaults = {
  products: null,
  deliveryAddressId: null,
  customerReference: null,
};
export const updateCartMutation = () => `
mutation updateCart(
  $clearCart: Boolean,
  $products: [CartItemInput],
  $deliveryAddressId: String,
  $customerReference: String,
  $recreateCartFromTemplateId: String,
) {
  updateCart(
    clearCart: $clearCart
    products: $products
    deliveryAddressId: $deliveryAddressId
    customerReference: $customerReference
    recreateCartFromTemplateId: $recreateCartFromTemplateId
  ) {
    results {
      messages {
        type
      }
      requestedQuantity
      resultantQuantity
      itemKey
      sku
    }
    cart {
      ${cartFields()}
    }
  }
}
`;
export const calculateCartMutation = () => `
mutation calculateCart($platform: String!, $shippingRateHandle: String) {
  calculateCart(
    platform: $platform
    shippingRateHandle: $shippingRateHandle
  ) {
    subtotalCost
    shippingCost
    taxCost
    totalCost
    shippingPriceIncludesTax
    shippingMethods {
      handle
      price
      title
    }
    allowedPaymentMethods {
      upfrontPayment
      paymentByInvoice
      paymentOnAccount
      hokodoPayment
      quote
    }
  }
}
`;
export const completeCartMutation = () => `
mutation completeCart($paymentType: String!, $platform: String!, $extraPaymentData: String!, $shippingRateHandle: String!) {
  completeCart(
    paymentType: $paymentType,
    platform: $platform
    extraPaymentData: $extraPaymentData
    shippingRateHandle: $shippingRateHandle
  ) {
    orderId
    goTo
  }
}
`;

export const loggedInCustomerQuery = () => `
query loggedInCustomer {
  loggedInCustomer {
    email
    name
    companyName
    addressEditingAllowed
  }
}
`;

export const fullLoggedInCustomerQuery = () => `
loggedInCustomer {
  email
  name
  companyName
  ${addressQuery()}
  paymentOnAccount {
    balance
    creditLimit
    currencyCode
  }
}
`;

export const recentOrderDataQuery = () => `
ordersRecent {
  id
  friendlyId
  createdAt
  status
  total {
    net
    gross
    currencyCode
  }
}
`;
export const yearOrderDataQuery = () => `
query ordersFromYear($year: String!) {
  ordersFromYear(year: $year) {
    id
    friendlyId
    createdAt
    status
    total {
      net
      gross
      currencyCode
    }
  }
}
`;
export const orderYearsQuery = () => `
orderYears {
  year
}
`;
export const orderQuery = () => `
query order($orderId: String!) {
  order(id: $orderId) {
    id
    customerReference
    customerId
    visibleId
    externalId
    createdAt
    status
    paymentMethod
    billingAddress {
      id
      title
      firstName
      lastName
      company
      addressLine1
      addressLine2
      city
      regionCode
      postalCode
      countryCode
      phone
      mobile
      externalId
      nickname
    }
    packages {
      id
      externalId
      visibleId
      createdAt
      shippingAddress {
        id
        title
        firstName
        lastName
        company
        addressLine1
        addressLine2
        city
        regionCode
        postalCode
        countryCode
        phone
        mobile
        externalId
        nickname
      }
      status
      shippedAt
      cancelledAt
      returnedAt
      shippingName
      shippingSku
      items {
        id
        externalId
        variantId
        sku
        name
        childName
        imageUrl
        slug
        quantity
        unitTotal {
          currencyCode
          taxRate
          net
          gross
        }
        lineTotal {
          currencyCode
          taxRate
          net
          gross
        }
      }
      totalSub {
        currencyCode
        taxRate
        net
        gross
      }
      totalShipping {
        currencyCode
        taxRate
        net
        gross
      }
      total {
        currencyCode
        taxRate
        net
        gross
      }
    }
    totalSub {
      currencyCode
      taxRate
      net
      gross
    }
    totalShipping {
      currencyCode
      taxRate
      net
      gross
    }
    total {
      currencyCode
      taxRate
      net
      gross
    }
  }
}
`;

export const cartTemplateCreateMutation = () => `
mutation cartTemplateCreate($name: String!) {
  cartTemplateCreate(name: $name) { id name updatedAt }
}
`;
export const cartTemplateDeleteMutation = () => `
mutation cartTemplateDelete($id: String!) {
  cartTemplateDelete(id: $id) { id }
}
`;
export const cartTemplateLoadQuery = () => `
cartTemplateList {
  id
  name
  updatedAt
}
`;
export const externallyCompletedCartMutation = () => `
mutation externallyCompletedCart($cartUuid: String!) {
  externallyCompletedCart(cartUuid: $cartUuid)
}
`;
export const createAddressMutation = () => `
mutation address($addressInput: CreateAddressInput!) {
  createAddress(
    createAddressInput: $addressInput,
  ) {
    ${addressQuery()}
  }
}
`;

export const updateAddressMutation = () => `
mutation address($addressInput: UpdateAddressInput!) {
  updateAddress(
    updateAddressInput: $addressInput,
  ) {
    ${addressQuery()}
  }
}
`;

export const deleteAddressMutation = () => `
mutation address($deleteAddressInput: DeleteAddressInput!) {
  deleteAddress(
    deleteAddressInput: $deleteAddressInput,
  ) {
    ${addressQuery()}
  }
}
`;

export const getAddressQuery = () => `
query GetAddress($id: String!) {
  address(id: $id) {
    id
    firstName
    lastName
    company
    addressLine1
    addressLine2
    city
    postalCode
    regionCode
    countryCode
    phone
  }
}
`;
export const regionsQuery = () => `
query regions($countryCode: String!) {
  regions(countryCode: $countryCode) { name code }
}
`;
