import { html } from 'lit-element';
import { useState, useEffect } from 'haunted/web.js';
import { get } from '@appnest/lit-translate';
import { classMap } from 'lit-html/directives/class-map';
import styles from './SparkAccount.scss';
import cartItemStyles from './SparkCartItem.scss';
import spark, { LS_SAVED_TEMPLATES, LS_USER_DATA, LS_WIDE_MODE } from '../spark.js';
import {
  createCurrencyFormatter,
  generateProductLink,
  newToast,
  shortDateFormat,
  useLocalStorage,
} from '../utils.js';
import {
  cartTemplateLoadQuery,
  createAddressMutation,
  deleteAddressMutation,
  fullLoggedInCustomerQuery,
  getAddressQuery,
  orderQuery,
  orderYearsQuery,
  recentOrderDataQuery,
  regionsQuery,
  updateAddressMutation,
  yearOrderDataQuery,
} from '../graphql.js';
import { SVG_EXPAND, SVG_LINE_DIVIDER, SVG_ARROW_DOWN, SVG_DELETE, SVG_EDIT } from '../svgs.js';
import { UI_STATE_CART } from './SparkDrawer.js';
import { saveTemplateDelete } from '../common/savedTemplates.js';
import countryCodeList from '../i18n/countryCodeList.js';

const UI_STATE_VIEW_ORDER = 'viewOrder';
const UI_STATE_LIST_ORDERS = 'listOrders';
const UI_STATE_INDEX = 'index';
const UI_STATE_ADDRESS = 'address';

function SparkAccount() {
  const [wideMode, setWideMode] = useLocalStorage(LS_WIDE_MODE, false);
  const [optionsDropdownViewable, setOptionsDropdownViewable] = useState(false);
  const [uiFlowState, setUiFlowState] = useState(UI_STATE_INDEX);
  const [addressFormState, setAddressFormState] = useState({});
  const [addressRegions, setAddressRegions] = useState([]);
  const loadRegions = async countryCode => {
    if (!countryCode) {
      setAddressRegions([]);
      return;
    }
    const res = await spark.fetch(regionsQuery(), { countryCode });
    const data = await res.json();
    setAddressRegions(data.data.regions);
    if (!data.data.regions.length) {
      // If regions comes back empty, reset region code.
      setAddressFormState({ ...addressFormState, ...{ regionCode: null, countryCode } });
    }
  };

  const [user] = useLocalStorage(LS_USER_DATA, null);
  const email = user?.userData?.email || '...';
  const addressEditingAllowed = user?.userData?.addressEditingAllowed;

  const onLogout = () => spark.logout();
  const onGoto = state => setUiFlowState(state);

  const [userData, setUserData] = useState(null);
  const [recentOrderData, setRecentOrderData] = useState(null);
  const [orderYearsData, setOrderYearsData] = useState(null);
  const [orderYearSelected, setOrderYearSelected] = useState(null);
  const [savedTemplates, setSavedTemplates] = useLocalStorage(LS_SAVED_TEMPLATES, false);
  useEffect(async () => {
    try {
      const result = await spark.fetch(
        [
          'query {',
          fullLoggedInCustomerQuery(),
          recentOrderDataQuery(),
          orderYearsQuery(),
          cartTemplateLoadQuery(),
          '}',
        ].join(''),
        {}
      );
      if (result.status !== 200) {
        throw new Error('failed');
      }
      const res = await result.json();
      setUserData(res.data.loggedInCustomer);
      setRecentOrderData(res.data.ordersRecent);
      setOrderYearsData(res.data.orderYears);
      if (res.data.orderYears.length) {
        setOrderYearSelected(res.data.orderYears[0].year);
      }
      setSavedTemplates(res.data.cartTemplateList);
    } catch (e) {
      newToast('error', get('global.toast.system-error'), false);
    }
  }, []);
  const [listOrderData, setListOrderData] = useState(null);
  useEffect(async () => {
    setListOrderData(null);
    if (!orderYearSelected) {
      return;
    }
    try {
      const result = await spark.fetch(yearOrderDataQuery(), { year: orderYearSelected });
      if (result.status !== 200) {
        throw new Error('failed');
      }
      const res = await result.json();
      setListOrderData(res.data.ordersFromYear.reverse());
    } catch (e) {
      newToast('error', get('global.toast.system-error'), false);
    }
  }, [orderYearSelected]);
  const [viewOrderId, setViewOrderId] = useState(null);
  const [viewOrder, setViewOrder] = useState(null);
  useEffect(async () => {
    setViewOrder(null);
    if (!viewOrderId) {
      return;
    }
    try {
      const result = await spark.fetch(orderQuery(), { orderId: viewOrderId });
      if (result.status !== 200) {
        throw new Error('failed');
      }
      const res = await result.json();
      setViewOrder(res.data.order);
    } catch (e) {
      newToast('error', get('global.toast.system-error'), false);
    }
  }, [viewOrderId]);
  const onGotoOrder = orderId => {
    setViewOrderId(orderId);
    setOptionsDropdownViewable(false);
    onGoto(UI_STATE_VIEW_ORDER);
  };
  /**
   * Cart Templates
   */
  const onSaveTemplateDelete = async id => {
    setSavedTemplates(null);
    await saveTemplateDelete(id, setSavedTemplates);
  };
  const onSaveTemplateLoad = async id => {
    this.dispatchEvent(new CustomEvent('view-update', { detail: { view: UI_STATE_CART } }));
    window.dispatchEvent(new CustomEvent('msg-bus-cart-template-load', { detail: { id } }));
  };
  /**
   * END Cart Templates
   */

  const formatGross = price => {
    return createCurrencyFormatter(price.currencyCode).format(price.gross);
  };
  const formatTax = price => {
    return createCurrencyFormatter(price.currencyCode).format(price.gross - price.net);
  };
  const formatNet = price => {
    return createCurrencyFormatter(price.currencyCode).format(price.net);
  };
  const formatAddress = address => {
    return ['company', 'addressLine1', 'addressLine2', 'city', 'postalCode']
      .map(key => address[key])
      .filter(v => !!v)
      .join(', ');
  };

  const orderTable = data => {
    if (data === null) {
      return html`<div class="account-item-table-loading"></div>`;
    }
    return html`
      <table class="striped highlight no-border">
        <thead>
          <tr>
            <th>${get('account.order-list.order')} ${get('account.order-list.id')}</th>
            <th>${get('account.order-list.date')}</th>
            <th class="column-hide">${get('account.order-list.amount')}</th>
            <th>${get('account.order-list.status')}</th>
            <th class="column-hide">${get('account.order-list.actions')}</th>
          </tr>
        </thead>

        <tbody>
          ${!data.length
            ? html`<tr>
                <td colspan="5">${get('account.order-list.empty')}</td>
              </tr>`
            : ''}
          ${data.length
            ? data.map(
                x => html`
                  <tr>
                    <td>
                      <a href="#" @click=${() => onGotoOrder(x.id)}>${x.friendlyId}</a>
                    </td>
                    <td>${shortDateFormat(x.createdAt)}</td>
                    <td class="column-hide">${formatGross(x.total)}</td>
                    <td class="status-icon status-${get(`account.order-status.${x.status}`)}">
                      <span><em>${get(`account.order-status.${x.status}`)}</em></span>
                    </td>
                    <td class="column-hide">
                      <a href="#" @click=${() => onGotoOrder(x.id)}
                        >${get('account.order-list.view')}</a
                      >
                    </td>
                  </tr>
                `
              )
            : ''}
        </tbody>
      </table>
    `;
  };

  const wideModeHtml = html`
    <button @click=${() => setWideMode(!wideMode)} class="btn-view-wide">
      ${SVG_EXPAND}
      <span>${get(`drawer.button-view.${wideMode ? 'reduce' : 'expand'}`)}</span>
    </button>
  `;

  let innerHtml = '';
  if (uiFlowState === UI_STATE_INDEX) {
    const recentOrdersHtml = html`
      <div class="section account-item">
        <div class="title-with-link">
          <h4>${get('account.main.recent-orders.header')}</h4>
          ${recentOrderData && recentOrderData.length
            ? html` <a href="#" @click=${() => onGoto(UI_STATE_LIST_ORDERS)}>
                ${get('account.main.recent-orders.view-all')}
              </a>`
            : ''}
          <div class="clearfix"></div>
        </div>

        ${orderTable(recentOrderData)}
      </div>
    `;

    let savedTemplatesTableHtml = '';
    if (savedTemplates === null) {
      savedTemplatesTableHtml = html`<div class="account-item-table-loading"></div>`;
    } else if (!savedTemplates.length) {
      savedTemplatesTableHtml = html`<div class="account-item-box">
        ${get(`account.main.saved-templates.empty`)}
      </div>`;
    } else {
      savedTemplatesTableHtml = html`
        <table class="striped highlight no-border">
          <thead>
            <tr>
              <th class="name">${get('account.main.saved-templates.name')}</th>
              <th>
                <span class="hide-mobile">${get('account.main.saved-templates.date')}</span>
                <span class="show-mobile">${get('account.main.saved-templates.date-short')}</span>
              </th>
              <th class="actions">${get('account.main.saved-templates.actions')}</th>
            </tr>
          </thead>

          <tbody>
            ${savedTemplates.map(
              x => html`
                <tr>
                  <td class="name">${x.name}</td>
                  <td>${shortDateFormat(x.updatedAt)}</td>
                  <td>
                    <span class="template-actions">
                      <button class="actions load" @click=${() => onSaveTemplateLoad(x.id)}>
                        ${get('account.main.saved-templates.load')}
                      </button>
                      <button class="actions delete" @click=${() => onSaveTemplateDelete(x.id)}>
                        ${SVG_DELETE}
                      </button>
                    </span>
                  </td>
                </tr>
              `
            )}
          </tbody>
        </table>
      `;
    }
    const savedTemplatesHtml = html`
      <div class="section account-item account-saved-templates">
        <h4>${get('account.main.saved-templates.header')}</h4>
        ${savedTemplatesTableHtml}
      </div>
    `;
    const otherAddresses = userData?.addresses.filter(
      x => x.id !== userData.defaultShippingAddressId && x.id !== userData.defaultBillingAddressId
    );
    const onGoToNewAddress = () => {
      setAddressFormState({});
      onGoto(UI_STATE_ADDRESS);
      setAddressRegions([]);
    };
    const onGoToEditAddress = async id => {
      onGoto(UI_STATE_ADDRESS);
      const result = await spark.fetch(getAddressQuery(), { id });
      if (result.status !== 200) {
        newToast('error', get('global.toast.system-error'), false);
      }
      const res = await result.json();
      setAddressFormState(res.data.address);
      loadRegions(res.data.address.countryCode);
    };
    const onDeleteAddress = async id => {
      // eslint-disable-next-line no-restricted-globals,no-alert
      if (!confirm(get('global.delete-confirm'))) {
        return;
      }
      const result = await spark.fetch(deleteAddressMutation(), { deleteAddressInput: { id } });
      if (result.status !== 200) {
        newToast('error', get('global.toast.system-error'), false);
      }
      const res = await result.json();
      setUserData({ ...userData, ...res.data.deleteAddress });
    };
    const addressBookHtml = html`
      <div class="section account-item">
        <div class="title-with-link">
          <h4>${get('account.main.address-book.header')}</h4>
          ${addressEditingAllowed
            ? html`<button @click=${() => onGoToNewAddress()}>
                ${get('account.main.address-book.new')}
              </button>`
            : ''}
          <div class="clearfix"></div>
        </div>
        ${!userData
          ? html` <div class="account-item-box">
              <div class="account-item-loading"></div>
            </div>`
          : html`
              <div class="account-item-box account-address-list">
                ${!userData.addresses.length
                  ? html`<div class="account-address-wrap address-default">
                      <div class="address-summary">
                        <span class="account-default"
                          >${get('account.main.address-book.no-addresses')}</span
                        >
                      </div>
                    </div>`
                  : ''}
                ${userData.defaultBillingAddressId &&
                userData.defaultBillingAddressId !== userData.defaultShippingAddressId
                  ? html`
                      <div class="account-address-wrap address-default">
                        <div class="address-summary">
                          <span class="account-default"
                            >${get('account.main.address-book.default-billing')}</span
                          >
                          <p>
                            ${userData?.addresses.find(
                              x => x.id === userData.defaultBillingAddressId
                            )?.description}
                          </p>
                        </div>
                        <div class="action-buttons">
                          ${addressEditingAllowed
                            ? html`
                                <button
                                  title="${get('account.main.address-book.edit')}"
                                  class="actions edit"
                                  @click=${() =>
                                    onGoToEditAddress(userData.defaultBillingAddressId)}
                                >
                                  ${SVG_EDIT}
                                </button>
                              `
                            : ''}
                        </div>
                      </div>
                    `
                  : ''}
                ${userData.defaultShippingAddressId
                  ? html`
                      <div class="account-address-wrap address-default">
                        <div class="address-summary">
                          <span class="account-default"
                            >${get('account.main.address-book.default-shipping')}</span
                          >
                          <p>
                            ${userData?.addresses.find(
                              x => x.id === userData.defaultShippingAddressId
                            )?.description}
                          </p>
                        </div>
                        <div class="action-buttons">
                          ${addressEditingAllowed
                            ? html`
                                <button
                                  title="${get('account.main.address-book.edit')}"
                                  class="actions edit"
                                  @click=${() =>
                                    onGoToEditAddress(userData.defaultShippingAddressId)}
                                >
                                  ${SVG_EDIT}
                                </button>
                              `
                            : ''}
                        </div>
                      </div>
                    `
                  : ''}
                ${otherAddresses.length
                  ? html`
                      ${otherAddresses.map(
                        x => html`
                          <div class="account-address-wrap">
                            <div class="address-summary">
                              <p class="min-spacing">${x.description}</p>
                            </div>
                            <div class="action-buttons">
                              ${addressEditingAllowed
                                ? html`
                                    <button
                                      class="actions edit"
                                      title="${get('account.main.address-book.edit')}"
                                      @click=${() => onGoToEditAddress(x.id)}
                                    >
                                      ${SVG_EDIT}
                                    </button>
                                    <button
                                      title="${get('account.main.address-book.delete')}"
                                      class="actions delete"
                                      @click=${() => onDeleteAddress(x.id)}
                                    >
                                      ${SVG_DELETE}
                                    </button>
                                  `
                                : ''}
                            </div>
                          </div>
                        `
                      )}
                    `
                  : ''}
              </div>
            `}
      </div>
    `;
    let paymentOnAccountHtml = '';
    if (userData && userData.paymentOnAccount) {
      const accountCurrencyFormatter = createCurrencyFormatter(
        userData.paymentOnAccount.currencyCode
      );
      paymentOnAccountHtml = html`
        <span class="account-default"
          >${get('account.main.my-details.payment-on-account.header')}</span
        >
        <p>
          ${get('account.main.my-details.payment-on-account.detail', {
            creditLimit: accountCurrencyFormatter.format(userData.paymentOnAccount.creditLimit),
            balance: accountCurrencyFormatter.format(userData.paymentOnAccount.balance),
          })}
        </p>
        <small>${get('account.main.my-details.payment-on-account.sub-text')}</small>
      `;
    }
    const accountDetailsHtml = html`<div class="section account-item">
      <h4>${get('account.main.my-details.header')}</h4>
      ${!userData
        ? html` <div class="account-item-box">
            <div class="account-item-loading"></div>
          </div>`
        : html`
            <div class="account-item-box">
              <span class="account-default">${get('account.main.my-details.email')}</span>
              <p>${userData.email}</p>
              <span class="account-default">${get('account.main.my-details.name')}</span>
              <p>${userData.name}</p>
              ${userData.companyName
                ? html` <span class="account-default"
                      >${get('account.main.my-details.company')}</span
                    >
                    <p>${userData.companyName}</p>`
                : ''}
              ${paymentOnAccountHtml}
            </div>
          `}
    </div>`;
    innerHtml = html`
      <div class="header-title">
        <h1>${get(`account.header.title`)}</h1>
        <p class="account-active">
          ${get('account.header.who', { email })}
          <button @click=${onLogout}>${get('account.header.logout')}</button>
        </p>

        ${wideModeHtml}
      </div>

      ${recentOrdersHtml} ${savedTemplatesHtml} ${accountDetailsHtml} ${addressBookHtml}
    `;
  } else if (uiFlowState === UI_STATE_LIST_ORDERS) {
    const listOrders = html`
      <div class="section account-item">
        <div class="title-with-link">
          <h4>${get('account.orders-list.header')}</h4>
          <div class="clearfix"></div>
        </div>

        ${orderTable(listOrderData)}
      </div>
    `;
    const onYearChange = e => {
      setOrderYearSelected(e.target.value);
    };
    innerHtml = html`
      <div class="header-title with-link">
        <h1>
          <a href="#" @click=${() => onGoto(UI_STATE_INDEX)}>
            ${get('account.order-view.header.my-account')}
          </a>
        </h1>

        <span class="line-divider">${SVG_LINE_DIVIDER}</span>
        <span class="active">${get('account.order-view.header.order-history')}</span>
        ${wideModeHtml}
      </div>

      ${orderYearsData
        ? html`
            <select class="orders-year" @change=${onYearChange} @blur=${onYearChange}>
              ${orderYearsData.map(x =>
                x === orderYearSelected
                  ? html` <option value=${x.year} selected>
                      ${get('account.header.order-placed-year')} ${x.year}
                    </option>`
                  : html` <option value=${x.year}>
                      ${get('account.order-list.order-placed-year')} ${x.year}
                    </option>`
              )}
            </select>
          `
        : ''}
      ${listOrders}
    `;
  } else if (uiFlowState === UI_STATE_VIEW_ORDER) {
    /* eslint-disable lit/attribute-value-entities */
    if (viewOrder === null) {
      innerHtml = html`
        <div class="header-title with-link">
          <h1>
            <a href="#" @click=${() => onGoto(UI_STATE_INDEX)}
              >${get('account.order-view.header.my-account')}</a
            >
          </h1>

          <span class="line-divider">${SVG_LINE_DIVIDER}</span>
          <span class="active">Loading...</span>
        </div>

        <div class="account-item-table-loading"></div>
      `;
    } else {
      const getPackageImportantDate = p => {
        switch (p.status) {
          case 'shipped':
            return shortDateFormat(p.shippedAt);
          case 'cancelled':
            return shortDateFormat(p.cancelledAt);
          case 'returned':
            return shortDateFormat(p.returnedAt);
          default:
            return '';
        }
      };

      const onReOrder = clearCart => {
        let reorderProducts = {};
        viewOrder.packages.forEach(p => {
          if (p.status === 'returned') {
            return;
          }
          p.items.forEach(i => {
            reorderProducts[i.sku] =
              i.sku in reorderProducts ? reorderProducts[i.sku] + i.quantity : i.quantity;
          });
        });
        reorderProducts = Object.keys(reorderProducts).map(sku => {
          return { sku, absoluteQuantity: reorderProducts[sku] };
        });
        this.dispatchEvent(new CustomEvent('view-update', { detail: { view: UI_STATE_CART } }));
        window.dispatchEvent(
          new CustomEvent('msg-bus-reorder', { detail: { products: reorderProducts, clearCart } })
        );
      };

      innerHtml = html`
        <div class="header-title with-link">
          <h1>
            <a href="#" @click=${() => onGoto(UI_STATE_INDEX)}>
              ${get('account.order-view.header.my-account')}
            </a>
          </h1>

          <span class="line-divider">${SVG_LINE_DIVIDER}</span>
          <span class="active">
            ${get('account.order-view.header.order-details')} ${viewOrder.visibleId ?? viewOrder.id}
          </span>

          <div class="options-dropdown">
            <button
              type="button"
              class="options-button ${classMap({ active: optionsDropdownViewable })}"
              @click=${() => setOptionsDropdownViewable(!optionsDropdownViewable)}
            >
              ${get('account.order-view.reorder.button-title')} ${SVG_ARROW_DOWN}
            </button>

            <div class="options-items ${classMap({ hide: !optionsDropdownViewable })}">
              <button @click=${() => onReOrder(true)} class="btn">
                ${get('account.order-view.reorder.button-clear')}
              </button>
              <button @click=${() => onReOrder(false)} class="btn">
                ${get('account.order-view.reorder.button-add')}
              </button>
            </div>
          </div>
        </div>

        <div class="order-detail-status">
          <div class="col-container">
            <div class="column">
              <h5>${get('account.order-view.header.status')}</h5>
              <p class="status-icon status-${viewOrder.status}">
                <span>${get(`account.order-status.${viewOrder.status}`)}</span>
              </p>
            </div>
            <div class="column">
              <h5>${get('account.order-view.header.placed-on')}</h5>
              <p>${shortDateFormat(viewOrder.createdAt)}</p>
            </div>
            <div class="column">
              <h5>${get('account.order-view.header.payment-method')}</h5>
              <p>${get(`account.order-view.payment-methods.${viewOrder.paymentMethod}`)}</p>
            </div>
            ${viewOrder.customerReference
              ? html`
                  <div class="column">
                    <h5>${get('account.order-view.header.customer-reference')}</h5>
                    <p>${viewOrder.customerReference}</p>
                  </div>
                `
              : ''}
          </div>

          <div class="order-details-address">
            <h5>${get('account.order-view.header.shipping-address')}</h5>
            <p>${formatAddress(viewOrder.packages[0].shippingAddress)}</p>
          </div>
        </div>

        <div class="packages">
          ${viewOrder.packages.map(
            p => html`
              <div class="packages-single">
                <div class="package-status status-${get(`account.order-status.${p.status}`)}">
                  <h4>${get(`account.order-status.${p.status}`)}</h4>
                  ${getPackageImportantDate(p)
                    ? html`
                        <span>
                          ${get('account.order-list.package-update')} ${getPackageImportantDate(p)}
                          ${p.visibleId}
                        </span>
                      `
                    : ''}
                </div>
                <div class="packages-single--items">
                  ${p.items.map(
                    i => html`
                      <div class="cart-item ${classMap({ 'view-wide': wideMode })}">
                        <span
                          class="cart-item--quantity-label status-${get(
                            `account.order-status.${p.status}`
                          )}"
                          >${i.quantity}</span
                        >
                        ${i.imageUrl
                          ? html`<div class="cart-item--image">
                              <a
                                href="${generateProductLink(i.slug)}"
                                style="background-image:url('${i.imageUrl}')"
                                title=${i.name}
                              >
                              </a>
                            </div>`
                          : html`<div class="cart-item--image"></div>`}
                        <div class="cart-item--details">
                          <div class="cart-product-name-code">
                            <h4>
                              ${i.slug
                                ? html`<a href="${generateProductLink(i.slug)}">${i.name}</a>`
                                : i.name}
                            </h4>
                            ${[`SKU: ${i.sku}`, i.childName]
                              .filter(x => x !== null && x !== '')
                              .join(' | ')}
                          </div>
                        </div>
                        <div class="cart-item--pricing">
                          <div class="cart-price-summary">
                            <h4>${formatNet(i.lineTotal)}</h4>
                            <p>${i.quantity} x ${formatNet(i.unitTotal)}</p>
                          </div>
                        </div>
                      </div>
                    `
                  )}
                </div>
              </div>
            `
          )}
        </div>
        <div class="order-totals">
          <div class="row">
            <div class="col l6">${get('global.totals.sub')}</div>
            <div class="col l6">${formatNet(viewOrder.totalSub)}</div>
          </div>
          <div class="row">
            <div class="col l6">${get('global.totals.shipping')}</div>
            <div class="col l6">${formatNet(viewOrder.totalShipping)}</div>
          </div>
          <div class="row">
            <div class="col l6">${get('global.totals.tax')}</div>
            <div class="col l6">${formatTax(viewOrder.total)}</div>
          </div>
          <div class="divider"></div>
          <div class="row grand-total">
            <div class="col l6">${get('global.totals.total')}</div>
            <div class="col l6">${formatGross(viewOrder.total)}</div>
          </div>
        </div>
      `;
    }
    /* eslint-enable lit/attribute-value-entities */
  } else if (uiFlowState === UI_STATE_ADDRESS) {
    const setInputFormState = (key, value) => {
      setAddressFormState({ ...addressFormState, ...{ [key]: value } });
      if (key === 'countryCode') {
        loadRegions(value);
      }
    };
    const onAddressInputChange = e => {
      setInputFormState(e.target.name, e.target.value);
    };
    const onSave = e => {
      e.preventDefault();
      spark
        .fetch(addressFormState?.id ? updateAddressMutation() : createAddressMutation(), {
          addressInput: addressFormState,
        })
        .then(x => x.json())
        .then(result => {
          setUserData({
            ...userData,
            ...result.data[addressFormState?.id ? 'updateAddress' : 'createAddress'],
          });
          onGoto(UI_STATE_INDEX);
        })
        .catch(() => {
          newToast('error', get('global.toast.system-error'), false);
        });
    };
    const onSetAsDefaultClicked = () => {
      setInputFormState('setAsDefault', !(addressFormState.setAsDefault ?? false));
    };
    const regionName = new Intl.DisplayNames([], { type: 'region' });
    const countries = countryCodeList.map(v => {
      return { k: v, v: regionName.of(v) };
    });

    innerHtml = html`
      <div class="account-address-edit">
        <div class="header-title with-link">
          <h1>
            <a href="#" @click=${() => onGoto(UI_STATE_INDEX)}
              >${get('account.order-view.header.my-account')}</a
            >
          </h1>
          <span class="line-divider">${SVG_LINE_DIVIDER}</span>
          <span class="active">${get('account.main.address-book.header')}</span>
        </div>
        <div class="section account-item">
          <h4>
            ${get(
              `account.main.address-book.form-title.${addressFormState?.id ? 'modify' : 'new'}`
            )}
          </h4>
        </div>
        <form class="row account-address" @submit=${onSave}>
          <div class="input-field">
            <input
              class="validate"
              maxlength="50"
              name="firstName"
              placeholder="${get('account.main.address-book.form.first-name')}"
              .value=${addressFormState.firstName ?? ''}
              @change=${onAddressInputChange}
            />
            <p>${get('account.main.address-book.form.first-name')}</p>
          </div>
          <div class="input-field">
            <input
              class="validate"
              maxlength="50"
              name="lastName"
              placeholder="${get('account.main.address-book.form.last-name')}"
              .value=${addressFormState.lastName ?? ''}
              @change=${onAddressInputChange}
            />
            <p>${get('account.main.address-book.form.last-name')}</p>
          </div>
          <div class="input-field">
            <input
              class="validate"
              maxlength="50"
              name="company"
              placeholder="${get('account.main.address-book.form.company')}"
              .value=${addressFormState.company ?? ''}
              @change=${onAddressInputChange}
            />
            <p>${get('account.main.address-book.form.company')}</p>
          </div>
          <div class="input-field">
            <input
              class="validate"
              required
              maxlength="50"
              name="addressLine1"
              placeholder="${get('account.main.address-book.form.line1')}"
              .value=${addressFormState.addressLine1 ?? ''}
              @change=${onAddressInputChange}
            />
            <p>${get('account.main.address-book.form.line1')}</p>
          </div>
          <div class="input-field">
            <input
              class="validate"
              maxlength="50"
              name="addressLine2"
              placeholder="${get('account.main.address-book.form.line2')}"
              .value=${addressFormState.addressLine2 ?? ''}
              @change=${onAddressInputChange}
            />
            <p>${get('account.main.address-book.form.line2')}</p>
          </div>
          <div class="input-field">
            <input
              class="validate"
              required
              maxlength="50"
              name="city"
              placeholder="${get('account.main.address-book.form.city')}"
              .value=${addressFormState.city ?? ''}
              @change=${onAddressInputChange}
            />
            <p>${get('account.main.address-book.form.city')}</p>
          </div>
          <div class="input-field">
            <input
              class="validate"
              maxlength="50"
              name="postalCode"
              placeholder="${get('account.main.address-book.form.postal-code')}"
              .value=${addressFormState.postalCode ?? ''}
              @change=${onAddressInputChange}
            />
            <p>${get('account.main.address-book.form.postal-code')}</p>
          </div>
          ${addressRegions.length
            ? html`<div class="input-field">
                <select name="regionCode" @blur=${onAddressInputChange}>
                  ${addressRegions.map(
                    x =>
                      html`<option
                        .selected=${addressFormState.regionCode === x.code}
                        value=${x.code}
                      >
                        ${x.name}
                      </option>`
                  )}
                </select>
                <p>${get('account.main.address-book.form.region')}</p>
              </div>`
            : ''}
          <div class="input-field">
            <select name="countryCode" @blur=${onAddressInputChange}>
              ${countries.map(
                x => html`
                  <option .selected=${addressFormState.countryCode === x.k} value=${x.k}>
                    ${x.v}
                  </option>
                `
              )}
            </select>
            <p>${get('account.main.address-book.form.country')}</p>
          </div>
          <div class="input-field checkbox-default">
            <label>
              <input
                type="checkbox"
                .checked=${addressFormState.setAsDefault ?? false}
                @click=${onSetAsDefaultClicked}
                class="filled-in"
              />
              <span> ${get('account.main.address-book.form.default')} </span>
            </label>
          </div>

          <button type="submit" class="btn btn-small">
            ${get('account.main.address-book.form-save')}
          </button>
        </form>
        <div class="action-cancel">
          <button @click=${() => onGoto(UI_STATE_INDEX)} class="btn-link">
            ${get('account.main.address-book.form-cancel')}
          </button>
        </div>
      </div>
    `;
  }

  return html`
    <style>
      ${styles}
      ${cartItemStyles}
    </style>
    ${innerHtml}
  `;
}

export default SparkAccount;
