import { html } from 'lit-element';
import { get } from '@appnest/lit-translate';
import { useState, useEffect } from 'haunted/web.js';
import { classMap } from 'lit-html/directives/class-map';
import styles from './SparkDrawer.scss';
import { removeComponentToPage, useLocalStorage } from '../utils.js';
import spark, { LS_USER_DATA, LS_WIDE_MODE } from '../spark.js';
import {
  SVG_ARROW_DOWN,
  SVG_TAB_ORDER,
  SVG_TAB_ACCOUNT,
  SVG_ARROW_RIGHT,
  SVG_LOGOUT,
} from '../svgs.js';

export const UI_STATE_ACCOUNT = 'account';
export const UI_STATE_CART = 'cart';

function SparkDrawer({ initialTab }) {
  const [optionsDropdownViewable, setOptionsDropdownViewable] = useState(false);
  const [uiFlowStatus, setUiFlowStatus] = useState(initialTab ?? UI_STATE_CART);
  const [wideMode] = useLocalStorage(LS_WIDE_MODE, false);
  const [newTimer, setNewTimer] = useState(true);

  spark.fetchUserData(); // Trigger a user data fetch, this grabs the email
  const [user] = useLocalStorage(LS_USER_DATA, null);
  const name = user?.userData?.name || '...';
  const nameAcronym = name.match(/\b\w/g)?.join('').toUpperCase().substring(0, 2) || '.';
  const companyName = user?.userData?.companyName || '';

  /**
   * Handles body styling and transitions in CSS.
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      setNewTimer(false);
    }, 1);
    // Ensure the page is not scrollable whilst the drawer is active
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
      clearTimeout(timer);
    };
  }, []);
  const onCloseDrawer = () => {
    setNewTimer(true);
    // This timeout here must equal the transition time within CSS
    setTimeout(() => removeComponentToPage('spark-drawer'), 500);
  };

  const onGoToCart = () => setUiFlowStatus(UI_STATE_CART);
  const onGoToAccount = () => setUiFlowStatus(UI_STATE_ACCOUNT);
  const onViewUpdate = e => setUiFlowStatus(e.detail.view);
  const onLogout = () => spark.logout();

  const tabs = html`
    <div class="navigation-bar">
      <ul>
        <li class="tab-cart ${classMap({ active: uiFlowStatus === UI_STATE_CART })}">
          <button @click=${onGoToCart}>
            ${SVG_TAB_ORDER}
            <span>${get('drawer.tabs.cart')}</span>
          </button>
        </li>
        <li class="tab-account ${classMap({ active: uiFlowStatus === UI_STATE_ACCOUNT })}">
          <button @click=${onGoToAccount}>
            ${SVG_TAB_ACCOUNT}
            <span>${get('drawer.tabs.account')}</span>
          </button>
        </li>
      </ul>

      <div class="user-wrapper options-dropdown">
        <button
          class="navigation-user ${classMap({ active: optionsDropdownViewable })}"
          @click=${() => setOptionsDropdownViewable(!optionsDropdownViewable)}
        >
          <div class="user-icon">
            <span><em>${nameAcronym}</em></span>
          </div>
          ${SVG_ARROW_DOWN}
        </button>

        <div class="options-items ${classMap({ hide: !optionsDropdownViewable })}">
          <div class="user-summary">
            <div class="user-icon">
              <span><em>${nameAcronym}</em></span>
            </div>
            <div class="user-details">
              <h4>${name}</h4>
              ${companyName
                ? html`<span>${companyName}</span>`
                : html`<span>${get('cart.header.company-fallback')}</span>`}
            </div>
          </div>

          <button class="with-icon" @click=${onLogout}>
            ${SVG_LOGOUT}
            <span>${get('cart.header.logout')}</span>
          </button>
        </div>
      </div>

      <button @click=${onCloseDrawer} class="btn-close" aria-label=${get('drawer.tabs.close')}>
        ${SVG_ARROW_RIGHT}
      </button>
    </div>
  `;

  /* eslint-disable lit-a11y/click-events-have-key-events */
  return html`
    <style>
      ${styles}
    </style>
    <div @click=${onCloseDrawer} class="overlay ${classMap({ 'overlay--hidden': newTimer })}"></div>
    <div
      class="drawer-container ${classMap({
        'drawer-container--wide': wideMode,
        'drawer-container--hidden': newTimer,
        'panel-account': uiFlowStatus === UI_STATE_ACCOUNT,
        'panel-cart': uiFlowStatus === UI_STATE_CART,
      })}"
    >
      ${tabs}
      <div class="drawer-container__inner">
        <spark-cart
          class="${classMap({ hide: uiFlowStatus !== UI_STATE_CART })}"
          @view-update=${onViewUpdate}
        >
        </spark-cart>
        <spark-account
          class="${classMap({ hide: uiFlowStatus !== UI_STATE_ACCOUNT })}"
          @view-update=${onViewUpdate}
        >
        </spark-account>
      </div>
    </div>
  `;
}

export default SparkDrawer;
