import { get } from '@appnest/lit-translate';
import { debounce } from 'throttle-debounce';
import { productsQuery } from './graphql.js';
import { newToast } from './utils.js';

export const EVENT_UPDATE_PRICE_DATA = 'update-price-data';
/**
 * todo long term, we could cache this further in local storage for less graphql calls between pages
 */
export default {
  spark: null,
  productsData: {},
  debouncedDataToFetch: [],
  /**
   * Initialise class
   * @param spark
   */
  init(spark) {
    this.spark = spark;
  },
  fetch(parentId) {
    if (!this.spark.isLoggedIn()) {
      throw new Error('Spark PriceData fetch called whilst not logged in');
    }
    if (parentId in this.productsData) {
      return this.productsData[parentId];
    }
    this.productsData[parentId] = {
      loading: true,
      notFound: false,
      error: false,
      data: null,
    };
    this.debouncedDataToFetch.push(parentId);
    // Debounce this so we gather all the items on the page and send them at once
    debounce(50, () => {
      if (!this.debouncedDataToFetch.length) {
        return;
      }
      const items = this.debouncedDataToFetch;
      // Empty the list to be fetched
      this.debouncedDataToFetch = [];
      this.spark
        .fetch(productsQuery(items))
        .then(async response => {
          const data = await response.json();
          const error = false;
          Object.keys(data.data).forEach(x => {
            const key = parseInt(x.replace('product', ''), 0);
            const respParentId = items[key];
            this.productsData[respParentId].loading = false;
            if (response.ok) {
              if (data.data[x]) {
                this.productsData[respParentId].data = data.data[x];
              } else {
                this.productsData[respParentId].notFound = true;
                // eslint-disable-next-line no-console
                console.error(`spark returned not found for ${respParentId}`);
              }
            } else {
              this.productsData[respParentId].error = true;
              // eslint-disable-next-line no-console
              console.error(`spark returned error for ${respParentId}`);
            }
            // Now update the message bus for components to listen to and get the updates!
            const event = new CustomEvent(EVENT_UPDATE_PRICE_DATA, {
              detail: {
                parentId: respParentId,
                state: this.productsData[respParentId],
              },
            });
            this.spark.msgBus.dispatchEvent(event);
          });
          if (error) {
            newToast('error', get('global.toast.system-error'), false);
          }
        })
        .catch(e => {
          newToast('error', get('global.toast.system-error'), false);
          // eslint-disable-next-line no-console
          console.error('sparkPriceData failed to fetch due to', e);
        });
    })();

    return this.productsData[parentId];
  },
  /**
   * Clear internal product data cache on logout
   */
  clearProductData() {
    this.productsData = {};
  },
};
