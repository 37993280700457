import { html } from 'lit-element';
import { virtual } from 'haunted/web.js';
import { get } from '@appnest/lit-translate';

export default virtual(() => {
  return html`
    <div class="message-quote">
      <spark-message type="success" message=${get('cart.checkout.payment-providers.quote.message')}>
      </spark-message>
    </div>
  `;
});
