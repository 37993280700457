import { get } from '@appnest/lit-translate';
import {
  externallyCompletedCartMutation,
  loggedInCustomerQuery,
  updateCartMutation,
  updateCartParamsDefaults,
} from './graphql.js';
import {
  applyBaseCSS,
  localStorageExternalUpdate,
  newToast,
  removeComponentToPage,
} from './utils.js';
import priceData from './priceData.js';

export const LS_CART_DATA = 'sparkLayerCartData';
export const LS_USER_DATA = 'sparkLayerUserData';
export const LS_CUSTOMER_ORDER_REF = 'sparkOrderCustomerReference';
export const LS_WIDE_MODE = 'sparkLayerWideMode';
export const LS_SAVED_TEMPLATES = 'sparkLayerSavedTemplates';

const LS_ITEMS = [
  LS_USER_DATA,
  LS_CART_DATA,
  LS_CUSTOMER_ORDER_REF,
  LS_WIDE_MODE,
  LS_SAVED_TEMPLATES,
];

let authTokenRequest;

const VERSION = `0.4`;

const spark = {
  options: {},
  msgBus: null,
  priceData,
  /**
   * Initialise SparkJS, this includes loading CSS to hide and show elements and hooking into
   * page links to show spark components
   *
   * @param options
   * @returns {Promise<void>}
   */
  async init(options) {
    this.msgBus = document.createComment('spark-event-bus');
    this.options = options;
    const loggedInPromise = this.isLoggedIn();
    if (options.onLoad instanceof Function) {
      options.onLoad(this);
    }
    this.priceData.init(this);
    return loggedInPromise;
  },
  /**
   * Check if user is logged in
   * @returns {Promise<boolean>}
   */
  async isLoggedIn() {
    try {
      const token = await this.getToken();
      return token !== null;
    } catch (e) {
      return false;
    }
  },
  /**
   * Action a logout and for external platforms ensure we log out the platform authentication
   */
  logout() {
    this.priceData.clearProductData();
    localStorageExternalUpdate(LS_USER_DATA, null);
    LS_ITEMS.forEach(k => localStorage.removeItem(k));
    // todo api logout token
    if (this.options.authLogoutUri) {
      // eslint-disable-next-line no-restricted-globals
      location.href = this.options.authLogoutUri;
    }
    removeComponentToPage('spark-drawer');
    applyBaseCSS(false);
  },
  /**
   * Get the authentication token used for API requests. Handles refreshing the token also when
   * needed
   *
   * This method is to ensure we de-duplicate the auth requests, as this is called multiple times
   * on initial page load, it is important that the authTokenRequest var is reset after use!
   * @returns {Promise<Response>|null|*}
   */
  getToken() {
    const userData = JSON.parse(localStorage.getItem(LS_USER_DATA));
    if (userData && parseInt(userData.expires_at, 10) > Date.now()) {
      return userData.access_token;
    }
    // If there's already a pending request return that
    if (authTokenRequest) {
      return authTokenRequest;
    }
    // Else check if we're using platform based auth and login with that
    if (this.options.auth.user) {
      // For platform based auth, action a login instead of refresh
      // todo delete previous tokens
      authTokenRequest = this._fetchToken({
        grant_type: 'password',
        username: this.options.auth.user,
        password: this.options.auth.token,
        client_id: 'storefront',
      });
      return authTokenRequest;
    }
    // Lastly, start a token refresh
    if (userData) {
      authTokenRequest = this._fetchToken({
        grant_type: 'refresh_token',
        refresh_token: userData.refresh_token,
        client_id: 'storefront',
      });
      return authTokenRequest;
    }
    return null;
  },
  /**
   * Fetches basic user data and stores it in localstorage ready for use. This should be called
   * in any component which uses user data.
   * @returns {Promise<void>}
   */
  async fetchUserData() {
    const userData = JSON.parse(localStorage.getItem(LS_USER_DATA))?.userData || {};
    if (userData?.email) {
      return;
    }
    const res = await this.fetch(loggedInCustomerQuery(), {});
    if (res.status !== 200) {
      return;
    }
    const userBody = await res.json();
    const newUserData = userBody.data.loggedInCustomer;
    localStorageExternalUpdate(LS_USER_DATA, {
      ...JSON.parse(localStorage.getItem(LS_USER_DATA)),
      userData: newUserData,
    });
  },
  async login(username, password) {
    return this._fetchToken({
      grant_type: 'password',
      username,
      password,
      client_id: 'storefront',
    });
  },
  async _fetchToken(body) {
    return fetch(
      `https://${this.options.sparkDomain}/api/auth/token?siteId=${this.options.siteId}&v=${VERSION}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Site-Id': this.options.siteId,
        },
        body: JSON.stringify(body),
      }
    )
      .then(response => {
        if (response.status !== 200) {
          const errorObj = new Error(`Token response failed - Status: ${response.status}`);
          errorObj.code = response.status;
          throw errorObj;
        }
        return response.json();
      })
      .then(jsonResponse => {
        const old = JSON.parse(localStorage.getItem(LS_USER_DATA)) ?? {};
        localStorageExternalUpdate(LS_USER_DATA, {
          ...old,
          access_token: jsonResponse.access_token,
          refresh_token: jsonResponse.refresh_token,
          expires_at: Date.now() + jsonResponse.expires_in * 100,
        });
        applyBaseCSS(true);
        return jsonResponse.access_token;
      })
      .catch(error => {
        this.logout();
        throw error;
      });
  },
  /**
   * Run a GraphQL query
   *
   * @param query
   * @param variables
   * @returns {Promise<Response>}
   */
  async fetch(query, variables) {
    const token = await this.getToken();
    authTokenRequest = undefined;
    return fetch(
      `https://${this.options.sparkDomain}/graphql?siteId=${this.options.siteId}&v=${VERSION}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Site-Id': this.options.siteId,
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ query, variables }),
      }
    );
  },
  /**
   * Run an update cart mutation, shows appropriate toasts and returns result
   *
   * @param {object} updates Follows the updateCart mutation input array
   * @param {boolean} inCart When the cart is shown success messaging is hidden, as updates are shown to the user
   * @param {boolean} messagingHandledLocally Do not show any toasts, ask result will be handled locally
   * @returns {Promise<null|*>} returns UpdateCartItemResult or null if error
   * @throws Error If request fails
   */
  async updateCart(updates, inCart, messagingHandledLocally) {
    try {
      const result = await this.fetch(updateCartMutation(), {
        ...updateCartParamsDefaults,
        ...updates,
      });
      const res = await result.json();
      const { cart, results } = res.data.updateCart;
      localStorageExternalUpdate(LS_CART_DATA, cart);
      if (!messagingHandledLocally) {
        results.forEach(cartItemUpdate => {
          cartItemUpdate.messages.forEach(m => {
            newToast('error', get(`global.toast.cart.${m.type}`), !inCart);
          });
        });
      }
      // If we've shown no feedback, show a success message! This is only needed here and not the cart
      if (!inCart && results.every(r => !r.messages.length)) {
        newToast('success', get(`global.toast.cart.success`), !inCart);
      }
      return results;
    } catch (e) {
      newToast('error', get(`global.toast.system-error`), false);
      return null;
    }
  },
  /**
   * For external platforms to inform Spark that the user has completed the checkout for a cart
   * these clears the persistent basket information before the webhooks reach our platform
   * @returns {Promise<void>}
   */
  async externalClearBasket() {
    const cart = JSON.parse(localStorage.getItem(LS_CART_DATA)) ?? null;
    if (!(cart || cart?.cartUuid)) {
      return;
    }
    const { cartUuid } = cart;
    await fetch(
      `https://${this.options.sparkDomain}/graphql?siteId=${this.options.siteId}&v=${VERSION}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Site-Id': this.options.siteId,
        },
        body: JSON.stringify({
          query: externallyCompletedCartMutation(),
          variables: { cartUuid },
        }),
      }
    );
    localStorage.removeItem(LS_CART_DATA);
    localStorage.removeItem(LS_CUSTOMER_ORDER_REF);
  },
};
window.spark = spark;
export default spark;
