import { html } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';
import { get } from '@appnest/lit-translate';
import styles from './SparkCartItem.scss';
import {
  calculateCartPriceForProduct,
  createCurrencyFormatter,
  generateProductLink,
  getPackSize,
  useLocalStorage,
} from '../utils.js';
import qtySelect from './SparkQtySelect.js';
import { LS_WIDE_MODE } from '../spark.js';

export default function SparkCartItem({ item, quantity }) {
  const [wideMode] = useLocalStorage(LS_WIDE_MODE, false);
  const cartItem = JSON.parse(item);
  const currencyFormatter = createCurrencyFormatter(cartItem.price.currencyCode);

  const subData = [`SKU: ${cartItem.sku}`].concat(
    cartItem.options.map(n => `${n.group}: ${n.value}`)
  );
  const packSize = getPackSize(cartItem);

  const totals = calculateCartPriceForProduct(cartItem, quantity);
  const lineTotal = cartItem?.lineTotal?.net ?? totals.total;
  const unitPrice = cartItem?.unitTotal?.net ?? totals.unit;

  const onQtyUpdated = e => {
    this.dispatchEvent(
      new CustomEvent('qty-updated', {
        detail: {
          qty: e.detail.qty,
          itemKey: cartItem.itemKey,
          sku: cartItem.sku,
        },
      })
    );
  };
  const onDelete = () => {
    this.dispatchEvent(
      new CustomEvent('qty-updated', {
        detail: { qty: 0, itemKey: cartItem.itemKey, sku: cartItem.sku },
      })
    );
  };

  return html`
    <style>
      ${styles}
    </style>
    <div class="cart-item ${classMap({ 'view-wide': wideMode })}">
      ${cartItem.cartImageUrl
        ? html`<div class="cart-item--image">
            <a
              href="${generateProductLink(cartItem.slug)}"
              style="background-image:url('${cartItem.cartImageUrl}')"
              alt=${cartItem.name}
            >
            </a>
          </div>`
        : html`<div class="cart-item--image"></div>`}
      <div class="cart-item--details">
        <div class="cart-item--details__name">
          <h4>
            ${cartItem.slug
              ? html`<a href="${generateProductLink(cartItem.slug)}">${cartItem.name}</a>`
              : cartItem.name}
          </h4>
          <p>${subData.join(' | ')}</p>
          ${cartItem.stockStatus === 'backorder'
            ? html`<span class="cart-item--details__back-order"
                >${get('pdp.messaging.back-order')}</span
              >`
            : ''}
        </div>
        <div class="cart-item--details-qty">
          ${qtySelect(quantity, packSize, cartItem.sku, onQtyUpdated)}
        </div>
      </div>
      <div class="cart-item--pricing">
        <div class="cart-price-summary">
          <h4>${currencyFormatter.format(lineTotal)}</h4>
          <p>${get('global.per-unit', { price: currencyFormatter.format(unitPrice) })}</p>
        </div>
        <button @click=${onDelete}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.5 5H17.5"
              stroke="black"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.66675 5V3.33333C6.66675 2.89131 6.84234 2.46738 7.15491 2.15482C7.46746 1.84226 7.89139 1.66666 8.33342 1.66666H11.6667C12.1087 1.66666 12.5327 1.84226 12.8452 2.15482C13.1578 2.46738 13.3334 2.89131 13.3334 3.33333V5H6.66675ZM15.8334 5V16.6667C15.8334 17.1087 15.6578 17.5326 15.3452 17.8452C15.0327 18.1577 14.6087 18.3333 14.1667 18.3333H5.83341C5.39139 18.3333 4.96746 18.1577 4.65491 17.8452C4.34234 17.5326 4.16675 17.1087 4.16675 16.6667V5H15.8334Z"
              stroke="black"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.33325 9.16666V14.1667"
              stroke="black"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M11.6667 9.16666V14.1667"
              stroke="black"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  `;
}
